import React from 'react';
import Icon from '../Icon';

const iconMaps = [
  <Icon icon="fas fa-map-marker-alt" />,
  <Icon icon="fas fa-box" />,
  <Icon icon="fas fa-user" />,
  <Icon icon="fas fa-clipboard-check" />,
];

const Steps = ({ steps, current = 1 }) => {
  const stepItems = steps.map((step, index) => {
    const additionalClass = current > index
      ? 'is-completed'
      : current === index
      ? 'is-active'
      : '';
    return (
      <div key={step} className={`step-item ${additionalClass}`}>
        <div className="step-marker">{iconMaps[index] || index + 1}</div>
        <div className="step-details">
          <p className="step-title">{step}</p>
        </div>
      </div>
    );
  });
  return (
    <div className="steps">
      {stepItems}
    </div>
  );
};

export default Steps;
