import React, { Component } from 'react';
import './login.css';

class Login extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { email: '', password: '' };
    this.updateCredentials = this.updateCredentials.bind(this);
    this.callLogin = this.callLogin.bind(this);
  }

  updateCredentials({ target }) {
    this.setState({ [target.name]: target.value });
  }

  callLogin() {
    const { email, password } = this.state;
    if (!email || !password) {
      this.props.setLoginError(true);
    } else {
      this.props.login({ email, password });
    }
  }

  render() {
    const errorMessage = !this.props.hasError ? null : (
      <article className="message is-danger">
        <div className="message-body">
          Credenciales inv&aacute;lidas.
        </div>
      </article>
    );
    return (
      <section className="hero is-success is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <h3 className="title has-text-grey">Login</h3>
              <p className="subtitle has-text-grey">Ingreso a la plataforma.</p>
              <div className="box">
                <figure className="avatar">
                  <img src={require('../../image/Logo.jpg')} alt="logo" />
                </figure>
                <form>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="email"
                        placeholder="Correo Electr&oacute;nico..."
                        name="email"
                        onChange={this.updateCredentials}
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-large"
                        type="password"
                        placeholder="Contrase&ntilde;a..."
                        name="password"
                        onChange={this.updateCredentials}
                        value={this.state.password}
                      />
                    </div>
                  </div>
                  {errorMessage}
                  <button
                    type="button"
                    className="button is-block is-info is-large is-fullwidth"
                    onClick={this.callLogin}
                  >
                    Ingresar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;
