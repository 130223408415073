import update from 'immutability-helper';

export const state = {
  lists: {},
  editableList: null,
  search: '',
};

export const reducers = {
  updateLists(state, data) {
    const lists = data.reduce((acum, list) => ({
      ...acum,
      [list.id]: list,
    }), {});
    return update(state, {
      lists: { $merge: lists },
    });
  },
  removeList(state, listId) {
    return update(state, {
      lists: {
        $apply: function(obj) {
          const copylists = {...obj};
          delete copylists[listId];
          return copylists;
        },
      },
    });
  },
  setEditableList(state, list) {
    return update(state, {
      editableList: { $set: list },
    });
  },
  setSearch(state, value) {
    return update(state, { search: { $set: value } });
  },
};
