import { connect } from 'react-redux';
import ListForm from '../components/ListForm';

const mapStateToProps = state => ({
  list: state.priceList.editableList,
});

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch.priceList.setEditableList(null);
    },
    setEditableList: list => {
      dispatch.priceList.setEditableList(list);
    },
    updateList: list => {
      dispatch.priceList.saveList(list);
    },
  };
};

const listFormContainer = connect(mapStateToProps, mapDispatchToProps)(ListForm);

export default listFormContainer;
