import React, { Component } from 'react';
import moment from 'moment';
import { dispatch } from '@rematch/core';
import AddressFields from './components/AddressFields';
import Icon from '../../../../components/Icon';
import HorizontalField from '../../../../components/HorizontalField';
import ErrorMessage from '../../../../components/ErrorMessage';

let validateTimeout;
class Addresses extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.inputHandler = this.inputHandler.bind(this);
  }

  componentDidMount() {
    const options = {
      lang: 'es',
      overlay: true,
      onSelect: date =>
        this.props.onInputChange({
          key: 'fecha_disponible',
          value: moment(date).format('YYYY-MM-DD'),
        }),
    };
    const startDate = !this.props.quote || !this.props.quote.fecha_disponible ? undefined :
      moment(this.props.quote.fecha_disponible);
    if (startDate) {
      options.startDate = startDate;
    }
    new window.bulmaCalendar(this.fechaDisponible, options);
    this.props.setSaved(false);
  }

  inputHandler({ target: { name: key, value: val, type, checked } }) {

    if (key === 'cp_origen' || key === 'cp_destino') {
      if (validateTimeout) {
        clearTimeout(validateTimeout);
      }
      dispatch.quote.setLoading(true);
      validateTimeout = setTimeout(() => dispatch.quote.validateCode({ key, code: val }), 500);
    }
    const value = type === 'checkbox' ? checked : val;
    this.props.onInputChange({ key, value });
  }

  render() {
    const { quote, errors, cpError, onInputsChange, originPoint, destinyPoint, loading } = this.props;
    const {
      nombre_origen,
      direccion_origen,
      cp_origen,
      id_punto_frecuente_origen,
      guardar_origen,
      nombre_destino,
      direccion_destino,
      cp_destino,
      guardar_destino,
      id_punto_frecuente_destino,
    } = quote;
    const origen = {
      nombre: { key: 'nombre_origen', value: nombre_origen },
      direccion: { key: 'direccion_origen', value: direccion_origen },
      codigo_postal: { key: 'cp_origen', value: cp_origen },
      guardar: { key: 'guardar_origen', value: guardar_origen },
      id_punto_frecuente: { key: 'id_punto_frecuente_origen', value: id_punto_frecuente_origen },
    };
    const destino = {
      nombre: { key: 'nombre_destino', value: nombre_destino },
      direccion: { key: 'direccion_destino', value: direccion_destino },
      codigo_postal: { key: 'cp_destino', value: cp_destino },
      guardar: { key: 'guardar_destino', value: guardar_destino },
      id_punto_frecuente: { key: 'id_punto_frecuente_destino', value: id_punto_frecuente_destino },
    };
    const fechaStyle = !quote.mercaderia_inmediata ? {} : { display: 'none' };

    const pisosField = !quote.es_edificio ? null : (
      <HorizontalField
        label="Pisos"
        placeholder="Pisos"
        icon={<Icon icon="fas fa-building" className="is-small is-left" />}
        controlClassName="has-icons-left"
        name="pisos"
        value={quote.pisos}
        onChange={this.inputHandler}
        error={errors.pisos}
        type="number"
        help="Especificar solo si se deben subir escaleras. El cliente es responsable de que la mercaderia entre en el ascensor, de no ser asi el transporte no garantiza dejar la mercaderia en el departamento"
      />
    );

    return (
      <React.Fragment>
      <h1 className="title is-centered">Origen</h1>
      <div className="box is-marginless">
        <AddressFields
          fields={origen}
          point={originPoint}
          loading={loading}
          errors={{ ...errors, ...cpError }}
          onChange={this.inputHandler}
          onChanges={onInputsChange}
        />
      </div>
      <h1 className="title is-centered">Destino</h1>
      <div className="box is-marginless">
        <AddressFields
          fields={destino}
          point={destinyPoint}
          loading={loading}
          errors={{ ...errors, ...cpError }}
          onChange={this.inputHandler}
          onChanges={onInputsChange}
        />
        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    style={{ marginRight: '5px' }}
                    name="es_edificio"
                    checked={quote.es_edificio}
                    onChange={this.inputHandler}
                  />
                  Es Edificio
                </label>
              </div>
            </div>
          </div>
        </div>
        {pisosField}
        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    style={{ marginRight: '5px' }}
                    name="cargo_transporte"
                    checked={quote.cargo_transporte}
                    onChange={this.inputHandler}
                  />
                  Cobro de viaje a cargo de transporte
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    style={{ marginRight: '5px' }}
                    name="mercaderia_inmediata"
                    checked={quote.mercaderia_inmediata}
                    onChange={this.inputHandler}
                  />
                  Disponibilidad Inmediata
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal" style={fechaStyle}>
          <div className="field-label is-normal">
            <label className="label">Fecha disponible</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control has-icons-left">
                <input
                  className={`input ${!errors.fecha_disponible ? '' : 'is-danger'}`}
                  type="text"
                  placeholder="Fecha disponible..."
                  ref={(input) => { this.fechaDisponible = input; }}
                  name="fecha_disponible"
                  value={quote.fecha_disponible}
                  onChange={this.inputHandler}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-calendar" />
                </span>
              </div>
              <ErrorMessage message={errors.fecha_disponible} show={!!errors.fecha_disponible} />
            </div>
          </div>
        </div>
        <HorizontalField
          label="Remito"
          placeholder="Remito..."
          type="text"
          name="remito"
          value={quote.remito}
          onChange={this.inputHandler}
        />
        <HorizontalField
          label="Observaciones"
          placeholder="Observaciones..."
          type="text"
          name="observaciones"
          value={quote.observaciones}
          onChange={this.inputHandler}
        />
      </div>
      </React.Fragment>
    );
  }
}

export default Addresses;
