import { createSelector } from 'reselect';
import { filter } from '../../utils/helpers';

export const lists = state => state.lists;
export const search = state => state.search;

export const filteredLists = createSelector(
  lists,
  search,
  (lists, search) => Object.values(lists).filter(filter(search, ['id', 'usuario_id'])),
);
