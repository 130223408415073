import React from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = ({ role }) => {
  const adminMenu = !role ? null : (
    <React.Fragment>
      <Link className="navbar-item is-hidden-tablet" to="/deliveries">Entregas</Link>
      <Link className="navbar-item is-hidden-tablet" to="/users">Usuarios</Link>
      <Link className="navbar-item is-hidden-tablet" to="/price-lists">Lista de Precios</Link>
      <Link className="navbar-item is-hidden-tablet" to="/export">Exportar Entregas</Link>
    </React.Fragment>
  );
  const misEntregas = role ? null : <Link className="navbar-item is-hidden-tablet" to="/deliveries">Mis Entregas</Link>;
  return (
    <React.Fragment>
      <Link className="navbar-item is-hidden-tablet" to="/quote">Cotizador</Link>
      <Link className="navbar-item is-hidden-tablet" to="/points">Puntos Frecuentes</Link>
      <Link className="navbar-item is-hidden-tablet" to="/products">Productos</Link>
      {misEntregas}
      {adminMenu}
    </React.Fragment>
  );
};

export default MobileMenu;
