import React from 'react';
import DeliverySummaries from './containers/deliverySummariesContainer';
import { dispatch } from '@rematch/core';

const Home = ({ rol, deliveries = [], history }) => {

  return (
    <div className="column route-content">
      <div className="content">
        <section className="section">
          <h1 className="title is-1 has-text-centered">Dashboard</h1>
          <DeliverySummaries />
        </section>
      </div>
    </div>
  )
};

export default Home;
