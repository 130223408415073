import React from 'react';

const PointItem = ({ point, url, history,rol }) => {
  const onClick = () => history.push(`${url}/${point.id}`);
  const clienteCol = !rol ? null : <td>{point.user.usuario}</td>;
  return (
    <tr onClick={onClick} key={point.id} style={{ cursor: 'pointer' }}>
      <td>{point.nombre}</td>
      <td>{point.sucursal ? 'Si' : 'No'}</td>
      <td>{point.direccion_origen}</td>
      <td>{point.codigo_postal}</td>
        {clienteCol}
    </tr>
  );
};

export default PointItem;
