import { connect } from 'react-redux';
import { select } from '@rematch/select';
import Home from '../Home';

const mapStateToProps = state => {
  const status = select.status.status(state);
  return {
    rol: state.auth.rol,
    deliveries: select.delivery.populatedDeliveries(state, status),
  };
};

const homeContainer = connect(mapStateToProps)(Home);

export default homeContainer;
