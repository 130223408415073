import update from 'immutability-helper';

export const state = {
  status: {},
  search: '',
};

export const reducers = {
  updateStatus(state, data) {
    const status = data.reduce((acum, statu) => ({
      ...acum, 
      [statu.id]: statu,
    }), {});
    return update(state, {
      status: { $merge: status },
    });
  },
  setSearch(state, value) {
    return update(state, {
      search: { $set: value },
    });
  },
};
