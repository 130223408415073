import React from 'react';
import { Route } from 'react-router-dom';
import ProductView from './containers/productViewContainer';
import TableProducts from './containers/tableProductsContainer';

const MasterProducts = ({ match }) => {
  return (
    <div className="column route-content">
      <div className="content">
        <Route path={`${match.url}/:id`} component={ProductView} />
        <Route exact path={match.url} component={TableProducts} />
      </div>
    </div>
  );
};

export default MasterProducts;
