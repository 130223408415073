import update from "immutability-helper";
import { quoteTemplate } from "./templates";

export const state = {
  currentStep: 0,
  // currentStep: 3,
  quote: quoteTemplate,
  // quote: {"nombre_origen":"La tienda","direccion_origen":"La direccione de la tienda a onde ire a buscar la cosa xoxo","cp_origen":"1212","guardar_origen":true,"contacto":"Nombre 1","contacto_mail":"correo@nombre","contacto_whatsapp":"4445566","contacto_telefono":"4443344","contacto_2":"nombre del alterno","contacto_2_mail":"email@alterno","contacto_2_whatsapp":"5553311","contacto_2_telefono":"5551133","nombre_destino":"Casa","direccion_destino":"Mi direccion particular de la casa xaxa","cp_destino":"1010","guardar_destino":false,"fecha_entrega":"","remito":"","cargo_transporte":true,"es_edificio":true,"pisos":"5","mercaderia_inmediata":false,"fecha_disponible":"2018-03-30"},
  products: [],
  // products: [{"producto":"Nombre product 1","alto":"100","ancho":"100","profundo":"50","cantidad":"1","valor_declarado":"1000"},{"producto":"Otro mas","alto":"50","ancho":"60","profundo":"100","cantidad":"2","valor_declarado":"3200"}],
  amount: null,
  errors: [],
  cpError: {},
  loading: false,
  saved: false,
  makeQuote: false,
};

export const reducers = {
  sumStep(state, value) {
    return update(state, {
      currentStep: { $set: state.currentStep + value },
    });
  },
  setStep(state, value) {
    return update(state, {
      currentStep: { $set: value },
    });
  },
  newQuote(state) {
    return update(state, {
      quote: { $set: quoteTemplate },
      products: { $set: [] },
    });
  },
  editQuote(state, quote) {
    if (quote.es_edificio && quote.pisos) {
      const pisos =
        quote.pisos < 1 ? 1 : quote.pisos > 3 ? quote.pisos : quote.pisos;
      if (pisos) {
        quote.pisos = parseInt(pisos, 10);
      }
    }
    return update(state, {
      quote: { $set: quote },
      makeQuote: { $set: true },
    });
  },
  newProduct(state, product) {
    return update(state, {
      products: { $push: [product] },
      makeQuote: { $set: true },
    });
  },
  editProduct(state, { index, product }) {
    return update(state, {
      products: {
        [index]: { $set: product },
      },
      makeQuote: { $set: true },
    });
  },
  removeProduct(state, index) {
    return update(state, {
      products: { $splice: [[index, 1]] },
      makeQuote: { $set: true },
    });
  },
  setMakeQuote(state, value) {
    return update(state, {
      makeQuote: { $set: value },
    });
  },
  setAmount(state, value) {
    return update(state, {
      amount: { $set: value },
    });
  },
  setErrors(state, value) {
    return update(state, {
      errors: { $set: value },
    });
  },
  setCpError(state, value) {
    return update(state, {
      cpError: { $set: value },
    });
  },
  setSaved(state, value) {
    return update(state, {
      saved: { $set: value },
    });
  },
  setLoading(state, value) {
    return update(state, {
      loading: { $set: value },
    });
  },
};
