import api from '../../services/api/';

const effects = {
  async fetchPoints() {
    try {
      const data = await api.frequentPoint.getAll();
      this.updatePoints(data);
    } catch (error) {
      console.log('Error in fetchPoints', error);
      // this.failFetchpoints(error);
    }
  },
  async savePoint({ point, history }) {
    try {
      this.setLoading(true);
      const { id, ...content } = point;
      const data = !id
        ? await api.frequentPoint.post(content)
        : await api.frequentPoint.put(id, content);
      this.updatePoints([data]);
      this.setLoading(false);
      if (!id) {
        history.push(`/points/${data.id}`);
      }
      this.fetchPoints();
    } catch (error) {
      this.setLoading(false);
      console.log('Error in savePoint', error);
      // this.failFetchpoints(error);
    }
  },
  async removePointEffect(pointId) {
    try {
      if (pointId) {
        await api.frequentPoint.delete(pointId);
        this.removePoint(pointId);
      }
    } catch (error) {
      console.log('Error in removePointEffect', error);
      // this.failFetchpoints(error);
    }
  },
  async validateCode(code) {
    try {
      this.setCpLoading(true);
      const data = await api.postalCode.validate('frecuent_point',code);
      if (!data || !data.length) {
        this.setCpError(true);
      } else {
        this.setCpError(false);
      }
      this.setCpLoading(false);
    } catch (error) {
      console.log('Error in validateCode', error);
    }
  },
};

export default effects;
