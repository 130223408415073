import { connect } from 'react-redux';
import { select } from '@rematch/select';
import TableProducts from  '../components/TableProducts';

const mapStateToProps = state => {
  return {
    products: select.masterProduct.filteredProducts(state),
    rol: state.auth.rol,
  };
};

const mapDispatchToProps = ({ masterProduct }) => {
  return {
    fetchProducts() {
      masterProduct.fetchProducts();
    },
    setSearch({ target: { value } }) {
      masterProduct.setSearch(value);
    },
  };
};

const TableProductsContainer = connect(mapStateToProps, mapDispatchToProps)(TableProducts);

export default TableProductsContainer;
