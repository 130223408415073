import { connect } from 'react-redux';
import { select } from '@rematch/select';
import TableLists from  '../components/TableLists';

const mapStateToProps = state => {
  return {
    lists: select.priceList.filteredLists(state),
  };
};

const mapDispatchToProps = ({ priceList }) => {
  return {
    fetchLists() {
      priceList.fetchLists();
    },
    setEditableList(data) {
      priceList.setEditableList(data);
    },
    removeList(listId) {
      priceList.removeListEffect(listId);
    },
    setSearch({ target: { value } }) {
      priceList.setSearch(value);
    },
  };
};

const tableListsContainer = connect(mapStateToProps, mapDispatchToProps)(TableLists);

export default tableListsContainer;
