import update from 'immutability-helper';

export const state = {
  users: {},
  editableUser: null,
  search: '',
};

export const reducers = {
  updateUsers(state, data) {
    const users = data.reduce((acum, user) => ({
      ...acum, 
      [user.id]: user,
    }), {});
    return update(state, {
      users: { $merge: users },
    });
  },
  removeUser(state, userId) {
    return update(state, {
      users: {
        $apply: function(obj) {
          const copyUsers = {...obj};
          delete copyUsers[userId];
          return copyUsers;
        },
      },
    });
  },
  setEditableUser(state, user) {
    return update(state, {
      editableUser: { $set: user },
    });
  },
  setSearch(state, value) {
    return update(state, { search: { $set: value } });
  },
};
