import api from '../../services/api/';

const effects = {
  async fetchStatus() {
    try {
      const data = await api.status.getAll();
      this.updateStatus(data);
    } catch (error) {
      console.log('Error in fetchStatus', error);
      // this.failFetchUsers(error);
    }
  },
};

export default effects;
