import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { select } from "@rematch/select";
import DeliverySummaries from "../components/DeliverySummaries";

const mapStateToProps = (state) => {
  const status = select.status.status(state);
  const summaries = select.delivery.summaries(state, status);
  const qtyPaymentPending = select.delivery.qtyPaymentPending(state);

  return {
    summaries,
    qtyPaymentPending,
    rol: state.auth.rol,
    deliveries: state.delivery.deliveries,
  };
};

const deliverySummariesContainer = withRouter(
  connect(mapStateToProps)(DeliverySummaries)
);

export default deliverySummariesContainer;
