import React, { Component } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';

class Payment extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      hasError: false,
    }
  }

  componentDidMount = async () => {
    const params = new URLSearchParams(this.props.location.search);
    const payment_id = params.get("collection_id");
    const payment_status = params.get("collection_status");
    const preference_id = params.get("preference_id")

    if (payment_status === "approved") {
      const request = await api.payment.savePayment({ payment_id: payment_id, preference_id: preference_id })
    } else {
      this.setState({ hasError: true })
    }
  }

  render() {
    const { hasError } = this.state

    return (
      <div className="column route-content">
        <div className="content">
          <section class="section">
            {!hasError && <div class="notification is-info">
              <button class="delete" onClick={() => this.props.history.push("/deliveries")}></button>
              Su pago esta siendo procesado, esto puede demorar algunos minutos, mientras vera el estado de su pago en <strong>Procesando</strong>,
              al finalizar el procesamiento el estado de su pago cambiara a <strong>Pagado</strong>.
              Puede ver el estado del mismo en la sección de <Link to="/deliveries"><a>Mis entregas</a>.</Link>
            </div>}
            {hasError && <div class="notification is-info">
              <button class="delete" onClick={() => this.props.history.push("/deliveries")}></button>
              Su pago no pudo ser procesado. Por favor verifique el estado del mismo en su cuenta de Mercado Pago.
            </div>}
          </section>
        </div>
      </div>

    )
  }
}

export default Payment;
