import React from 'react';
import Icon from '../../../../components/Icon';

const UserItem = ({ user, onEditClick, onDeleteClick, openChangeModal, loggedUser, generateToken }) => {
  return (
    <tr key={user.id}>
      <td>{user.usuario}</td>
      <td>{user.razon_social}</td>
      <td>{user.email}</td>
      <td>
        <button
          className="button is-small is-warning"
          onClick={() => onEditClick(user)}
          title="Editar"
        >
          <Icon icon="fas fa-edit" />
        </button>
        <button
          className="button is-small is-danger"
          onClick={() => onDeleteClick(user.id)}
          title="Eliminar"
        >
          <Icon icon="fas fa-trash" />
        </button>
        <button
          className="button is-small is-default"
          onClick={() => openChangeModal(user.id)}
          disabled={loggedUser === user.usuario}
        >
          Cambiar contrase&ntilde;a
        </button>
        <button
          className="button is-small is-default"
          onClick={() => generateToken(user.id)}
          disabled={loggedUser === user.usuario}
        >
          Generar Token
        </button>
      </td>
    </tr>
  );
};

export default UserItem;
