import React, { Component } from 'react';
import moment from 'moment';
import EditableField from '../../../../../../components/EditableField';

class BasicView extends Component {
  constructor(props) {
    super();
    this.props = props;
  }

  componentDidUpdate(prevProps) {
    if (!!prevProps.editable !== !!this.props.editable && this.props.editable) {
      const options = {
        lang: 'es',
        overlay: true,
        onSelect: date =>
          this.props.onChange({ target: {
            name: 'fecha_entrega',
            value: moment(date).format('YYYY-MM-DD'),
          }}),
      };
      new window.bulmaCalendar(this.fechaEntrega, options);
    }
  }

  render() {
    const { delivery: originalDelivery, status, statuses, editable, loading, onChange } = this.props;
    const delivery = editable || originalDelivery;
    const statusesOptions = Object.values(statuses).map(item => (
      <option key={item.id} value={item.id}>{item.estado}</option>
    ));
    const nombreStatus = !status ? '' : status.estado;
    const statusItem = !editable ? nombreStatus : (
      <div className="select is-small">
        <select name="estado_id" value={delivery.estado_id} onChange={onChange}>
          {statusesOptions}
        </select>
      </div>
    );
    const statusMailOptions = ['', 'CORREO ENVIADO', 'ACEPTADO', 'RECHAZADO'].map(item => (
      <option key={item} value={item}>{item || 'PENDIENTE'}</option>
    ));
    const nombreStatusMail = delivery.estado_usuario_correo || 'PENDIENTE';
    const statusMail = !editable ? nombreStatusMail : (
      <div className="select is-small">
        <select name="estado_usuario_correo" value={delivery.estado_usuario_correo} onChange={onChange}>
          {statusMailOptions}
        </select>
      </div>
    );
    const volumen = !delivery.envios_detalles ? 0
      : delivery.envios_detalles.reduce((acum, { ancho, alto, profundo }) => {
        const vol = (parseFloat(ancho)/100) * (parseFloat(alto)/100) * (parseFloat(profundo)/100);
        return acum + vol;
      }, 0);
    return (
      <React.Fragment>
        <ul>
          <li>
            <span>Numero de entrega: </span>
            {delivery.id}
          </li>
          <li>
            <span>Fecha de creaci&oacute;n: </span>
            {moment(delivery.created_at).format('DD/MM/YYYY HH:mm')}
          </li>
          <li>
            <span>Cotizado: </span>
            <EditableField
              value={delivery.cotizacion}
              name="cotizacion"
              disabled={loading}
              editable={editable}
              onChange={onChange}
              prefix="$"
            />
          </li>
          <li>
            <span>Volumen: </span>
            {volumen.toFixed(1)} m3
          </li>
          <li>
            <span>Remito: </span>
            <EditableField
              value={delivery.remito}
              name="remito"
              disabled={loading}
              editable={editable}
              onChange={onChange}
            />
          </li>
          <li>
            <span>Fecha de entrega: </span>
            <EditableField
              value={delivery.fecha_entrega}
              name="fecha_entrega"
              disabled={loading}
              editable={editable}
              onChange={onChange}
              refer={input => { this.fechaEntrega = input; }}
            />
          </li>
          <li>
            <span>Estado: </span>
            {statusItem}
          </li>
          <li>
            <span>Estado Mail: </span>
            {statusMail}
          </li>
          <li>
            <span>Fecha Envio Mail: </span>
            {!delivery.fecha_envio_mail ? '' : moment(delivery.fecha_envio_mail).format('DD/MM/YYYY HH:mm')}
          </li>
          <li>
            <span>Facturaci&oacute;n: </span>
            <EditableField
              value={delivery.facturacion}
              name="facturacion"
              disabled={loading}
              editable={editable}
              onChange={onChange}
            />
          </li>
          <li>
            <span>Observaci&oacute;n: </span>
            <EditableField
              value={delivery.observaciones}
              name="observaciones"
              disabled={loading}
              editable={editable}
              onChange={onChange}
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

export default BasicView;
