import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class PriceList {
  static async getAll() {
    const response = await axios.get(`${apiUrl}/listaPrecios`, authHeader());
    return response.data;
  }
  static async put(listId, list) {
    const response = await axios.put(`${apiUrl}/listaPrecios/${listId}`, list, authHeader());
    return response.data;
  }
  static async post(list) {
    const response = await axios.post(`${apiUrl}/listaPrecios`, list, authHeader());
    return response.data;
  }
  static async delete(listId) {
    const response = await axios.delete(`${apiUrl}/listaPrecios/${listId}`, authHeader());
    return response.data;
  }
}

export default PriceList;
