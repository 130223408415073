import { connect } from 'react-redux';
import { select } from "@rematch/select";
import StatusModal from '../components/StatusModal';

const mapStateToProps = state => ({
  statuses: select.status.filteredStatus(state),
  search: select.status.search(state),
});

const mapDispatchToProps = ({ status: { setSearch, fetchStatus } }) => ({
  setSearch({ target: { value } }) {
    setSearch(value);
  },
  fetchStatus,
});

const statusModalContainer = connect(mapStateToProps, mapDispatchToProps)(StatusModal);

export default statusModalContainer;
