import { createSelector } from 'reselect';
import { filter } from '../../utils/helpers';

export const products = state => state.products;
export const productById = (state, { productId }) => state.products[productId];
export const search = state => state.search;
export const loading = state => state.loading;

export const filteredProducts = createSelector(
  products,
  search,
  (products, search) => Object.values(products)
    .filter(filter(search, ['id', 'usuario_id']))
    .sort((a, b) => {
      if (a.producto.toLowerCase() < b.producto.toLowerCase()) {
        return -1;
      }
      if (a.producto.toLowerCase() > b.producto.toLowerCase()) {
        return 1;
      }
      return 0;
    }),
);
