import { connect } from 'react-redux';
import { select } from '@rematch/select';
import TablePoints from  '../components/TablePoints';

const mapStateToProps = state => {
  return {
    points: select.frequentPoint.filteredPoints(state),
    rol: state.auth.rol,
  };
};

const mapDispatchToProps = ({ frequentPoint }) => {
  return {
    fetchPoints() {
      frequentPoint.fetchPoints();
    },
    setSearch({ target: { value } }) {
      frequentPoint.setSearch(value);
    },
  };
};

const tablePointsContainer = connect(mapStateToProps, mapDispatchToProps)(TablePoints);

export default tablePointsContainer;
