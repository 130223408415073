import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import Steps from '../../components/Steps';
import Addresses from './components/Addresses';
import Products from './components/Products';
import Contact from './components/Contact';
import Summary from './components/Summary';
import Completed from './components/Completed';
import { quoteStepValidation } from '../../utils/helpers';

const steps = ['Direcciones', 'Productos', 'Contacto', 'Resumen'];
const stepsComponents = [Addresses, Products, Contact, Summary, Completed];

class Quote extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputsChange = this.onInputsChange.bind(this);
    this.onDeleteQuote = this.onDeleteQuote.bind(this);
    this.saveQuote = this.saveQuote.bind(this);

    this.state = {
      alertDelivery: false
    }
  }

  componentDidMount() {
    const { currentStep } = this.props;
    if (currentStep >= 4) {
      this.onDeleteQuote();
    }
    var date = new Date();
    if (date.getHours() > 16 && date.getHours() < 24) {
      this.setState({alertDelivery: true})
    }
  }

  saveQuote() {
    const { quote, products } = this.props;
    this.props.saveQuote({ quote: { ...quote, productos: products }, save: true });
  }

  next() {
    const { quote, products, currentStep, sumStep, setErrors, originPoint, destinyPoint } = this.props;
    if (currentStep <= steps.length) {
      const overrideContact = !originPoint || !destinyPoint ? null : destinyPoint;
      const errors = quoteStepValidation({ ...quote, products, overrideContact }, currentStep);
      if (!errors || !errors.length) {
        sumStep(1);
        window.scrollTo(0, 0);
      }
      setErrors(errors);
    }
  }

  previous() {
    if (this.props.currentStep > 0) {
      this.props.sumStep(-1);
      this.props.setErrors([]);
      window.scrollTo(0, 0);
    }
  }

  onInputChange({ key, value }) {
    this.props.editQuote({ ...this.props.quote, [key]: value });
  }

  onInputsChange(inputs) {
    const updated = inputs.reduce(
      (quote, { key, value }) =>
        ({...quote, [key]: value}),
      this.props.quote,
    );
    this.props.editQuote(updated);
  }

  onDeleteQuote() {
    this.props.newQuote();
    this.props.setStep(0);
    this.props.setSaved(false);
    this.props.setErrors([]);
    this.props.setAmount(null);
  }

  

  render() {

    const {
      currentStep,
      quote,
      products,
      amount,
      saved,
      errors,
      cpError,
      newProduct,
      editProduct,
      removeProduct,
      setErrors,
      setSaved,
      setAmount,
      originPoint,
      destinyPoint,
      makeQuote,
      loading,
    } = this.props;
    const normalizedErrors = errors.reduce((acum, error) =>
      ({ ...acum, [error.key]: error.message}), {});
    const StepComponent = stepsComponents[currentStep];
    const buttons = currentStep < 4 ? (
      <div className="columns is-mobile" style={{ marginTop: '5px' }}>
        <div className="column is-half">
          <button className="button is-pulled-right" onClick={this.previous} disabled={!currentStep}>
            <Icon icon="fas fa-arrow-left" />
            <span>Atras</span>
          </button>
        </div>
        <div className="column is-half">
          <button className="button is-success" onClick={this.next} disabled={Object.keys(cpError).length || loading}>
            <span>Siguiente</span>
            <Icon icon="fas fa-arrow-right" />
          </button>
        </div>
      </div>
    ) : (
      <div className="columns is-mobile is-centered">
        <div className="column is-half">
          <Link className="button is-success" to="/deliveries">
            Ir a Mis entregas
          </Link>
        </div>
      </div>
    );
    const overrideContact = !originPoint || !destinyPoint ? null : destinyPoint;
    return (
      
      <div className="column route-content">
        <div className={`modal ${this.state.alertDelivery? 'is-active' : ''}`}>
          <div className="modal-background" onClick={() => this.setState({alertDelivery: false})} />
            <div className="modal-content">
              <div className="box">
                <h1 className="title is-6 is-centered">Los envios cargados luego de las 17 horas se tomaran con fecha del día siguiente.
                  En caso de urgencias comunicarse directamente con nosotros.
                </h1>   
              </div>
            </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({alertDelivery: false})} />
        </div>
        <div className="content">
          <section className="section">
            <h1 className="title is-1 is-centered">Cotizaci&oacute;n</h1>
            <button
              className="button is-danger"
              onClick={this.onDeleteQuote}
              style={{
                position: 'relative',
                top: '-110px',
              }}
              title="Borrar cotizacion"
            >
              <Icon icon="fas fa-eraser" />
            </button>
            <Steps steps={steps} current={currentStep} />
            <StepComponent
              quote={quote}
              products={products}
              amount={amount}
              saved={saved}
              makeQuote={makeQuote}
              errors={normalizedErrors}
              cpError={cpError}
              loading={loading}
              originPoint={originPoint}
              destinyPoint={destinyPoint}
              overrideContact={overrideContact}
              newProduct={newProduct}
              editProduct={editProduct}
              removeProduct={removeProduct}
              setErrors={setErrors}
              setAmount={setAmount}
              saveQuote={this.saveQuote}
              setSaved={setSaved}
              onInputChange={this.onInputChange}
              onInputsChange={this.onInputsChange}
            />
            {buttons}
          </section>
        </div>
      </div>
    );
  };
}

export default Quote;
