import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class FrequentPoint {
  static async getAll() {
    const response = await axios.get(`${apiUrl}/puntosFrecuentes`, authHeader());
    return response.data;
  }
  static async put(pointId, point) {
    const response = await axios.put(`${apiUrl}/puntosFrecuentes/${pointId}`, point, authHeader());
    return response.data;
  }
  static async post(point) {
    const response = await axios.post(`${apiUrl}/puntosFrecuentes`, point, authHeader());
    return response.data;
  }
  static async delete(pointId) {
    const response = await axios.delete(`${apiUrl}/puntosFrecuentes/${pointId}`, authHeader());
    return response.data;
  }
}

export default FrequentPoint;
