import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class DeliveryApi {
  static async getAll() {
    const response = await axios.get(`${apiUrl}/envios`, authHeader());
    return response.data;
  }
  static async getByRange(startDate, endDate) {
    const query = [];
    if (startDate) {
      query.push(`fechaDesde=${startDate} 00:00:00`);
    }
    if (endDate) {
      query.push(`fechaHasta=${endDate} 23:59:00`);
    }
    let getParams = query.length ? `?${query.join('&')}` : '';
    const response = await axios.get(`${apiUrl}/envios${getParams}`, authHeader());
    return response.data;
  }
  static async quote(quote, save) {
    quote.guardar = save;
    const response = await axios.post(`${apiUrl}/envios`, quote, authHeader());
    return response.data;
  }
  static async updateStatus(deliveryId, statusId) {
    const response = await axios.put(`${apiUrl}/envios/${deliveryId}`, { estado_id: statusId }, authHeader());
    return response.data;
  }
  static async put(deliveryId, { envios_detalles, ...delivery }, oldDelivery) {
    // Update envios_detalles
    const { envios_detalles: oldDetalles } = oldDelivery;
    const updateds = envios_detalles.filter(item => item.id);
    const createds = envios_detalles.filter(item => !item.id);
    // Creados
    if (createds.length) {
      await Promise.all(createds.map(
        async detalle => axios.post(`${apiUrl}/enviosDetalles`, { envios_id: deliveryId,...detalle },  authHeader())
      ));
    }
    // Editados
    if (updateds.length) {
      await Promise.all(updateds.map(
        async detalle => axios.put(`${apiUrl}/enviosDetalles/${detalle.id}`, detalle,  authHeader())
      ));
    }
    // Borrados
    const detallesIds = updateds.map(item => item.id);
    const deleteds = oldDetalles.filter((old) => !detallesIds.includes(old.id));
    if (deleteds.length) {
      await Promise.all(deleteds.map(
        async detalle => axios.delete(`${apiUrl}/enviosDetalles/${detalle.id}`, authHeader())
      ));
    }
    // Update delivery
    const response = await axios.put(`${apiUrl}/envios/${deliveryId}`, delivery, authHeader());
    return response.data;
  }
  // Delete delivery
  static async delete(deliveryId) {
    const response = await axios.delete(`${apiUrl}/envios/${deliveryId}`, authHeader());
    return response.data;
  }
  // actualizacion masiva de deliveries
  static async massiveUpdate(payload) {
    const response = await axios.put(`${apiUrl}/envios`,payload, authHeader());
    return response;
  }

}

export default DeliveryApi;
