import axios from "axios";
import { apiUrl } from "../../config/path";

const authHeader = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
});

class PaymentApi {
  static async getPreferencePayment(payload) {
    const response = await axios.post(
      `${apiUrl}/preferenciasPagos`,
      payload,
      authHeader()
    );
    return response;
  }
  static async savePayment(payload) {
    const response = await axios.post(
      `${apiUrl}/registrarPagos`,
      payload,
      authHeader()
    );
    return response;
  }
}

export default PaymentApi;
