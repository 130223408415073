import update from "immutability-helper";
import moment from "moment";

export const state = {
  deliveries: {},
  editableDelivery: null,
  filtersSelected: { estado_id: [0, 1, 2, 3, 5] },
  search: "",
  loading: false,
  cpError: {},
  cpLoading: false,
  startDate: moment().subtract(3, "months"),
  endDate: null,
};

export const reducers = {
  updateDeliveries(state, data) {
    const deliveries = data.reduce(
      (acum, delivery) => ({
        ...acum,
        [delivery.id]: delivery,
      }),
      {}
    );
    return update(state, {
      deliveries: { $merge: deliveries },
    });
  },
  clearDeliveries(state) {
    return update(state, {
      deliveries: { $set: {} },
    });
  },
  setEditableDelivery(state, delivery) {
    return update(state, {
      editableDelivery: { $set: delivery },
    });
  },
  addFilterSelected(state, { filter, value }) {
    if (!state.filtersSelected[filter]) {
      return update(state, {
        filtersSelected: {
          [filter]: { $set: [value] },
        },
      });
    }
    return update(state, {
      filtersSelected: {
        [filter]: { $push: [value] },
      },
    });
  },
  removeFilterSelected(state, { filter, value }) {
    const index = state.filtersSelected[filter].findIndex(
      (item) => item === value
    );
    return update(state, {
      filtersSelected: {
        [filter]: { $splice: [[index, 1]] },
      },
    });
  },
  clearFiltersSelected(state) {
    return update(state, {
      filtersSelected: { $set: {} },
    });
  },
  setSearch(state, value) {
    return update(state, { search: { $set: value } });
  },
  setLoading(state, value) {
    return update(state, { loading: { $set: value } });
  },
  setCpError(state, value) {
    return update(state, {
      cpError: { $set: value },
    });
  },
  setCpLoading(state, value) {
    return update(state, {
      cpLoading: { $set: value },
    });
  },
  setDateRange(state, { startDate, endDate }) {
    return update(state, {
      startDate: { $set: startDate },
      endDate: { $set: endDate },
    });
  },
  removeDelivery(state, deliveryId) {
    return update(state, {
      deliveries: {
        $apply: function (obj) {
          const copyDelivery = { ...obj };
          delete copyDelivery[deliveryId];
          return copyDelivery;
        },
      },
    });
  },
  setFiltersStateSelected(state, value) {
    const states = {
      BORRADOR: 0,
      "PENDIENTE DE COORDINACIÓN": 1,
      "EN RUTEO": 2,
      "EN REPARTO": 3,
      ENTREGADO: 4,
      DEVUELTO: 5,
    };
    return {
      ...state,
      filtersSelected: {
        ["estado_id"]: [states[value]],
      },
    };
  },
  setFiltersPaymentSelectedUSer(state, value) {
    return {
      ...state,
      filtersSelected: {
        ["estado_pago"]: [value],
        ["cargo_transporte"]: [false],
      },
    };
  },
  setFiltersPaymentSelected(state, value) {
    return {
      ...state,
      filtersSelected: {
        ["estado_pago"]: [value],
      },
    };
  },
};
