import { connect } from 'react-redux';
import { select } from '@rematch/select';
import FrequentPointsModal from '../FrequentPointsModal';

const mapStateToProps = state => ({
  points: select.frequentPoint.filteredPoints(state),
  search: select.frequentPoint.search(state),
});

const mapDispatchToProps = ({ frequentPoint: { setSearch, fetchPoints } }) => ({
  setSearch({ target: { value } }) {
    setSearch(value);
  },
  fetchPoints,
});

const frequentPointsModalContainer = connect(mapStateToProps, mapDispatchToProps)(FrequentPointsModal);

export default frequentPointsModalContainer;
