import api from '../../services/api/';

const effects = {
  async fetchDeliveries(payload = {}, rootState) {
    try {
      const { startDate, endDate } = rootState.delivery;
      const start = !startDate ? null : startDate.format('YYYY-MM-DD');
      const end = !endDate ? null : endDate.format('YYYY-MM-DD');
      const data = await api.delivery.getByRange(start, end);
      this.clearDeliveries();
      this.updateDeliveries(data);
    } catch (error) {
      console.log('Error in fetchDeliveries', error);
    }
  },
  async fetchDeliveriesByRange({ startDate, endDate }) {
    try {
      this.setDateRange({ startDate, endDate });
      this.clearFiltersSelected();
      this.fetchDeliveries();
    } catch (error) {
      console.log('Error in fetchDeliveries', error);
    }
  },
  async saveDelivery({ delivery, oldDelivery }) {
    try {
      this.setLoading(true);
      const { id, ...content } = delivery;
      if(!id) {
        await api.delivery.post(content)
      } else {
        await api.delivery.put(id, content, oldDelivery);
      }
      this.setEditableDelivery(null);
      this.setLoading(false);
      this.fetchDeliveries();
    } catch (error) {
      this.setLoading(false);
      console.log('Error in saveDelivery', error);
    }
  },
  async updateDeliveryStatus({ deliveryId, statusId }) {
    try {
      await api.delivery.updateStatus(deliveryId, statusId);
      this.fetchDeliveries();
    } catch (error) {
      console.log('Error in updateDeliveryStatus', error);
    }
  },
  async validateCode({ key, code }, rootState) {
    try {
      this.setCpLoading(true)
      const data = await api.postalCode.validate(key,code);
      const { cpError } = rootState.delivery;
      if (!data || !data.length) {
        this.setCpError({ ...cpError, [key]: 'Codigo Postal no encontrado' });
      } else {
        const newCp = { ...cpError };
        delete newCp[key];
        this.setCpError(newCp);
      }
      this.setCpLoading(false);
    } catch (error) {
      this.setCpLoading(false);
      console.log('Error in fetchDeliveries', error);
    }
  },
  //agregado por MI para borrar un delivery
  async removeDeliveryEffect(deliveryId) {
    try {
      if (deliveryId) {
        // ejecuto el borrado en el BE
        await api.delivery.delete(deliveryId);
        // ejecuto el borrado en redux
        this.removeDelivery(deliveryId);
      }
    } catch (error) {
      console.log('Error in removeDeliveryEffect', error);
    }
  }
};

export default effects;
