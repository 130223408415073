import { connect } from 'react-redux';
import { select } from '@rematch/select';
import Quote from '../Quote';

const mapStateToProps = state => {
  const quote = select.quote.quote(state);
  const { id_punto_frecuente_origen, id_punto_frecuente_destino } = quote;
  const originPoint = !id_punto_frecuente_origen
    ? null
    : select.frequentPoint.pointById(state, { pointId: id_punto_frecuente_origen });
  const destinyPoint = !id_punto_frecuente_destino
    ? null
    : select.frequentPoint.pointById(state, { pointId: id_punto_frecuente_destino });
  return {
    currentStep: select.quote.currentStep(state),
    quote,
    products: select.quote.products(state),
    amount: select.quote.amount(state),
    errors: select.quote.errors(state),
    cpError: select.quote.cpError(state),
    saved: select.quote.saved(state),
    makeQuote: select.quote.makeQuote(state),
    loading: select.quote.loading(state),
    originPoint,
    destinyPoint,
  };
};

const mapDispatchToProps = ({ quote: {
  setStep,
  sumStep,
  newQuote,
  editQuote,
  newProduct,
  editProduct,
  removeProduct,
  quote: saveQuote,
  setErrors,
  setSaved,
  setAmount,
} }) => ({
  setStep,
  sumStep,
  newQuote,
  editQuote,
  newProduct,
  editProduct,
  removeProduct,
  saveQuote,
  setErrors,
  setSaved,
  setAmount,
});

const quoteContainer = connect(mapStateToProps, mapDispatchToProps)(Quote);

export default quoteContainer;