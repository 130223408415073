export const quoteTemplate = {
  nombre_origen: '',
  direccion_origen: '',
  cp_origen: '',
  guardar_origen: false,
  contacto: '',
  contacto_mail: '',
  contacto_whatsapp: '',
  contacto_telefono: '',
  contacto_2: '',
  contacto_2_mail: '',
  contacto_2_whatsapp: '',
  contacto_2_telefono: '',
  nombre_destino: '',
  direccion_destino: '',
  cp_destino: '',
  guardar_destino: false,
  fecha_entrega: '',
  remito: '',
  cargo_transporte: false,
  es_edificio: false,
  pisos: '',
  mercaderia_inmediata: true,
  fecha_disponible: '',
  aditionalWeight: false,
  draft: false
};

export const productTemplate = {
  producto: '',
  alto: '',
  ancho: '',
  profundo: '',
  cantidad: '',
  valor_declarado: '',
  observaciones: '',
  peso: '',
};
