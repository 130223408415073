import { connect } from 'react-redux';
import PointForm from '../components/PointForm';

const mapStateToProps = state => ({
  point: state.frequentPoint.editablePoint,
});

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch.frequentPoint.setEditablePoint(null);
    },
    setEditablePoint: point => {
      dispatch.frequentPoint.setEditablePoint(point);
    },
    updatePoint: point => {
      dispatch.frequentPoint.savePoint(point);
    },
  };
};

const pointFormContainer = connect(mapStateToProps, mapDispatchToProps)(PointForm);

export default pointFormContainer;
