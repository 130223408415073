import React, { Component } from 'react';

class ProductDimensions extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.onDimensionChange = this.onDimensionChange.bind(this);
  }

  onDimensionChange() {
    const alto = this.inputAlto.value;
    const ancho = this.inputAncho.value;
    const profundo = this.inputProfundo.value;
    this.props.onChange({ alto, ancho, profundo });
  }

  render() {
    const { alto, ancho, profundo } = this.props;
    return (
      <React.Fragment>
        <div style={{ marginBottom: '5px' }}>
          <div className="field has-addons">
            <p className="control"><a className="button is-static" style={{ width: '100px' }}>Alto</a></p>
            <p className="control is-expanded">
              <input
                className="input"
                type="number"
                placeholder="Alto..."
                ref={(input) => { this.inputAlto = input; }}
                value={alto}
                onChange={this.onDimensionChange}
              />
            </p>
            <p className="control"><a className="button is-static">cm</a></p>
          </div>
        </div>
        <div style={{ marginBottom: '5px' }}>
          <div className="field has-addons">
            <p className="control"><a className="button is-static" style={{ width: '100px' }}>Ancho</a></p>
            <p className="control is-expanded">
              <input
                className="input"
                type="number"
                placeholder="Ancho..."
                ref={(input) => { this.inputAncho = input; }}
                value={ancho}
                onChange={this.onDimensionChange}
              />
            </p>
            <p className="control"><a className="button is-static">cm</a></p>
          </div>
        </div>
        <div style={{ marginBottom: '5px' }}>
          <div className="field has-addons">
            <p className="control"><a className="button is-static" style={{ width: '100px' }}>Profundo</a></p>
            <p className="control is-expanded">
              <input
                className="input"
                type="number"
                placeholder="Profundo..."
                ref={(input) => { this.inputProfundo = input; }}
                value={profundo}
                onChange={this.onDimensionChange}
              />
            </p>
            <p className="control"><a className="button is-static">cm</a></p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductDimensions;
