import React from 'react';

const ConfirmModal = ({ title, message, onConfirm, onCancel}) => (
  <div className="modal is-active">
    <div className="modal-background" onClick={onCancel}></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        <button className="delete" aria-label="close" onClick={onCancel}></button>
      </header>
      <section className="modal-card-body">
        {message}
      </section>
      <footer className="modal-card-foot">
        <button className="button is-success" onClick={onConfirm}>Confirmar</button>
        <button className="button" onClick={onCancel}>Cancelar</button>
      </footer>
    </div>
    <button className="modal-close is-large" aria-label="close" onClick={onCancel}></button>
  </div>
);

export default ConfirmModal;
