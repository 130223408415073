import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class StatusApi {
  static async getAll() {
    const response = await axios.get(`${apiUrl}/estados`, authHeader());
    return response.data;
  }
}

export default StatusApi;
