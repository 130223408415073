import update from 'immutability-helper';

export const state = {
  logged: !!localStorage.getItem('access_token'),
  user: '',
  rol: 0,
  loginError: false,
  loading: false,
  openChangePasswordModal: false,
};

export const reducers = {
  setLogged(state, value) {
    return update(state, { logged: { $set: value } });
  },
  setUser(state, value) {
    return update(state, { user: { $set: value } });
  },
  setRol(state, value) {
    return update(state, { rol: { $set: value } });
  },
  setLoginError(state, value) {
    return update(state, { loginError: { $set: value } });
  },
  setLoading(state, value) {
    return update(state, { loading: { $set: value } });
  },
  setOpenChangePasswordModal(state, value) {
    return update(state, { openChangePasswordModal: { $set: value } });
  },
};
