import { connect } from 'react-redux';
import Header from '../Header';

const mapStateToProps = state => {
  return {
    logged: state.auth.logged,
    user: state.auth.user,
    rol: state.auth.rol,
  };
};

const mapDispatchToProps = ({ auth: { logout, setOpenChangePasswordModal } }) => {
  return { logout, setOpenChangePasswordModal };
};

const headerContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default headerContainer;
