import React, { Component } from "react";
import EditableField from "../../../../../../components/EditableField";
import moment from "moment";

class DeliveryModal extends Component {
  constructor() {
    super();
  }

  componentDidMount = () => {
    const options = {
      lang: "es",
      overlay: true,
      onSelect: (date) =>
        this.onChange({
          target: {
            name: "fecha_entrega",
            value: moment(date).format("YYYY-MM-DD"),
          },
        }),
    };
    new window.bulmaCalendar(this.fechaEntrega, options);
  };

  map = new Map();

  onChange = ({ target: { name, value } }) => {
    if (value) {
      this.map.set(name, value);
    } else {
      this.map.delete(name);
    }
  };
  save = () => {
    this.props.save(Object.fromEntries(this.map));
  };

  closeModal = () => {
    this.props.closeModal();
  };

  statusesOptions = () => {
    const options = [];

    options.push(
      <option key={-1} value={-1}>
        {"SELECCIONE"}
      </option>
    );

    Object.values(this.props.statuses).map((item) => {
      options.push(
        <option key={item.id} value={item.id}>
          {item.estado}
        </option>
      );
    });

    return options;
  };

  render() {
    const statusMailOptions = [
      "",
      "PENDIENTE",
      "CORREO ENVIADO",
      "ACEPTADO",
      "RECHAZADO",
    ].map((item) => (
      <option key={item} value={item}>
        {item || "SELECCIONE"}
      </option>
    ));
    const statusMail = (
      <div className="select is-small">
        <select name="estado_usuario_correo" onChange={this.onChange}>
          {statusMailOptions}
        </select>
      </div>
    );

    const statusItem = (
      <div className="select is-small">
        <select name="estado_id" onChange={this.onChange}>
          {this.statusesOptions()}
        </select>
      </div>
    );
    const cobradoOpciones = ["", "Pendiente", "Pagado"].map((item) => (
      <option key={item} value={item}>
        {item || "SELECCIONE"}
      </option>
    ));

    const cobrado = (
      <div className="select is-small">
        <select name="estado_pago" onChange={this.onChange}>
          {cobradoOpciones}
        </select>
      </div>
    );
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title is-marginless">
              Actualizacíon masiva de entregas
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={this.closeModal}
            />
          </header>
          <section className="modal-card-body">
            <ul>
              <li>
                <span>Fecha de entrega: </span>
                <EditableField
                  name="fecha_entrega"
                  onChange={this.onChange}
                  editable
                  refer={(input) => {
                    this.fechaEntrega = input;
                  }}
                />
              </li>
              <li>
                <span>Estado: </span>
                {statusItem}
              </li>
              <li>
                <span>Estado Mail: </span>
                {statusMail}
              </li>
              <li>
                <span>Facturaci&oacute;n: </span>
                <EditableField
                  name="facturacion"
                  onChange={this.onChange}
                  editable
                />
              </li>
              <li>
                <span>Observaci&oacute;n: </span>
                <EditableField
                  name="observaciones"
                  onChange={this.onChange}
                  editable
                />
              </li>
              <li>
                <span>Estado Pago: </span>
                {cobrado}
              </li>
            </ul>
          </section>
          <footer className="modal-card-foot">
            <div style={{ textAlign: "center", width: "100%" }}>
              <button className="button is-success" onClick={this.save}>
                Guardar
              </button>
              <button className="button" onClick={this.closeModal}>
                Cancelar
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default DeliveryModal;
