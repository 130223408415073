import React from 'react';
import { Link } from 'react-router-dom';
import { dispatch } from '@rematch/core';
import ChangePasswordModal from '../ChangePasswordModal';

const SideMenu = ({ role }) => {
  const adminMenu = !role ? null : (
    <React.Fragment>
      <p className="menu-label">
        Administrador
      </p>
      <ul className="menu-list">
        <li><Link to="/deliveries">Entregas</Link></li>
        <li><Link to="/users">Usuarios</Link></li>
        <li><Link to="/price-lists">Lista de Precios</Link></li>
      </ul>
    </React.Fragment>
  );
  const misEntregas = role ? null : <li><Link to="/deliveries">Mis Entregas</Link></li>;
  return (
    <React.Fragment>
      <ChangePasswordModal />
      <div className="is-hidden-mobile" style={{ width: '214px' }} />
      <div className="column is-2 is-hidden-mobile app-side-menu" style={{ width: '214px' }}>
        <Link to="/">
          <figure className="image side-logo">
            <img src={require('../../image/logo-negativo.png')} alt="logo" />
          </figure>
        </Link>
        <aside className="menu">
          <ul className="menu-list">
            <li><Link to="/">Dashboard</Link></li>
            {misEntregas}
            <li><Link to="/quote">Cotizador</Link></li>
            <li><Link to="/points">Puntos Frecuentes</Link></li>
            <li><Link to="/products">Productos</Link></li>
            <li><Link to="/export">Exportar Entregas</Link></li>
          </ul>
          {adminMenu}
        </aside>
        <a className="navbar-item side-change" onClick={() => dispatch.auth.setOpenChangePasswordModal(true)}>Cambiar Contrase&ntilde;a</a>
        <a className="navbar-item side-logout" onClick={dispatch.auth.logout}>Salir</a>
      </div>
    </React.Fragment>
  );
};

export default SideMenu;
