import React, {Component} from 'react';
import './selectProduct.css';

class SelectProduct extends  Component  {
  constructor(props) {
    super();
}

onChange = (event) => {
//console.log(event.target.value)
     this.props.onChange(event);
};

  render () {

    const SelectAllProducts = Object.values(this.props.products).map(product => (
      <option key={product.id}
       value={`${product.alto}-${product.ancho}-${product.profundo}-${product.peso}-${product.valor_declarado}-${product.producto}`}>
       {product.producto}</option>
    ));
    //alto,ancho,profundo,peso,valor_declarado,producto

    return (
      <div className="field is-horizontal" style={{ margin: '10px' }}>
        <div className="field-label is-normal">
        <label className="label">Productos</label>
        </div>
    <div className="field-body" >
    <select name="selectProducto"  onChange={this.onChange}>
      <option defaultValue value='' >Seleccione o cargue manual</option>
      {SelectAllProducts}
    </select>
    </div>
  </div>

   )
  }

}

export default SelectProduct;
