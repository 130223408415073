import { default as user } from './user';
import { default as frequentPoint } from './frequentPoint';
import { default as status } from './status';
import { default as priceList } from './priceList';
import { default as delivery } from './delivery';
import { default as quote } from './quote';
import { default as auth } from './auth';
import { default as masterProduct } from './masterProduct';

export default {
  user,
  frequentPoint,
  status,
  priceList,
  delivery,
  quote,
  auth,
  masterProduct,
};
