import React from 'react';
import { Route } from 'react-router-dom';
import PointView from './containers/pointViewContainer';
import TablePoints from './containers/tablePointsContainer';

const FrequentPoints = ({ match }) => {
  return (
    <div className="column route-content">
      <div className="content">
        <Route path={`${match.url}/:id`} component={PointView} />
        <Route exact path={match.url} component={TablePoints} />
      </div>
    </div>
  );
};

export default FrequentPoints;
