import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProductItem from '../ProductItem';
import Icon from '../../../../components/Icon';
import SearchInput from '../../../../components/SearchInput';

class TableProducts extends Component {
  constructor(props) {
    super();
    this.props = props;
  }

  componentDidMount() {
    this.props.fetchProducts();
  }

  render() {
    const { products, setSearch, rol } = this.props;
    const { match, history } = this.props;
    const { url } = match;
    const rows = products.map(product =>
      <ProductItem
        product={product}
        key={product.id}
        url={url}
        history={history}
        rol={rol}
      />
    );
    const clienteCol = !rol ? null : <th>Cliente</th>;
    return (
      <div className="section">
        <h1 className="title is-1 has-text-centered">Productos</h1>
        <Link
          className="button is-primary is-large is-rounded"
          to="/products/new"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            width: '55px',
            borderRadius: '100%'
          }}
        >
          <Icon icon="fas fa-plus" />
        </Link>
        <SearchInput onChange={setSearch} />
        <div className="box is-marginless">
          <table className="table is-narrow is-hoverable is-fullwidth">
            <thead>
            <tr>
              <th>Producto</th>
              <th>Alto (cm)</th>
              <th>Ancho (cm)</th>
              <th>Profundo (cm)</th>
              <th>Peso (kg)</th>
              <th>Valor Declarado</th>
              {clienteCol}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TableProducts;
