import React from 'react';

const ProductCard = ({ index, product, onEditClick, onRemoveClick }) => (
  <div className="card">
    <header className="card-header">
      <p className="card-header-title">
        {product.producto}
      </p>
    </header>
    <div className="card-content">
      <div className="content">
        <p>Cantidad: {product.cantidad}</p>
        <p>Volumen(cm): {product.ancho}x{product.alto}x{product.profundo}</p>
        <p>Valor declarado: ${product.valor_declarado}</p>
      </div>
    </div>
    <footer className="card-footer">
      <a className="card-footer-item" onClick={() => onEditClick({ index, product })}>Editar</a>
      <a className="card-footer-item" onClick={() => onRemoveClick({ index })}>Eliminar</a>
    </footer>
  </div>
);

export default ProductCard;
