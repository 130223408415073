const messages = {
  required: 'Campo requerido',
  requiredProducts: 'Debe haber al menos un producto',
  requiredContact: 'Debe proporcionar al menos dos maneras de ser contactado.',
};

export const filter = (query, blackList = []) => (value) => {
  if (typeof value === 'string') {
    return value.toLowerCase().includes(query.toLowerCase());
  } else if (typeof value === 'number') {
    return value.toString().toLowerCase().includes(query.toLowerCase());
  } else if (Array.isArray(value)) {
    return value.filter(filter(query)).length > 0;
  } else if (typeof value === 'object' && value !== null) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, val] of Object.entries(value)) {
      if (!blackList.includes(key) && filter(query, blackList)(val)) {
        return true;
      }
    }
    return false;
  }
  return false;
};

export const quoteStepValidation = (quote, step) => {
  switch (step) {
    case 0: {
      return adressesValidation(quote);
    }
    case 1: {
      return productsValidation(quote.products);
    }
    case 2: {
      return contactValidation(quote);
    }
    default:
      return [];
  }
};

const addressesRequireds = [
  'cp_origen',
  'cp_destino',
];

export const adressesValidation = quote => {
  return Object.entries(quote).reduce((errors, [key, value]) => {
    // normal required
    if (addressesRequireds.includes(key) && !value) {
      return [ ...errors, { key, message: messages.required }];
    } else if (key === 'es_edificio' && value && !parseInt(quote.pisos, 10)) {
      return [ ...errors, { key: 'pisos', message: messages.required }];
    } else if (key === 'mercaderia_inmediata' && !value && !quote.fecha_disponible) {
      return [ ...errors, { key: 'fecha_disponible', message: messages.required }];
    } else {
      return errors;
    }
  }, []);
};

export const productsValidation = (products = []) => {
  if (!products.length) {
    return [{ key: 'products', message: messages.requiredProducts }];
  }
  return [];
};


export const contactValidation = quote => {
  const errors = [];
  if (quote.overrideContact) {
    return errors;
  }
  if (!quote.contacto) {
    errors.push({ key: 'contacto', message: messages.required });
  }
  const { contacto_mail, contacto_telefono, contacto_whatsapp } = quote;
  let howMany = 0;
  if (contacto_mail) {
    howMany += 1;
  }
  if (contacto_telefono) {
    howMany += 1;
  }
  if (contacto_whatsapp) {
    howMany += 1;
  }
  if (howMany < 2) {
    errors.push({ key: 'contactos', message: messages.requiredContact });
  }
  return errors;
};
