import React from 'react';
import { dispatch } from '@rematch/core';

const DeliverySummary = ({ summary: { number, estado }, history, rol }) => {

  const onClick = () => {
    if (estado === "PENDIENTE DE PAGO") {
      rol ? dispatch.delivery.setFiltersPaymentSelected("Pendiente") : dispatch.delivery.setFiltersPaymentSelectedUSer("Pendiente")
    } else {
      dispatch.delivery.setFiltersStateSelected(estado)
    }
    history.push('/deliveries');
  };
  return (
    <div className="tile is-parent" onClick={onClick} style={{ cursor: 'pointer' }}>
      <article className="tile is-child box">
        <p className="title">{number}</p>
        <p className="subtitle">{estado}</p>
      </article>
    </div>
  );
};

export default DeliverySummary;
