import React from 'react';

const PointMiniCard = ({ point }) => {
  if (!point) {
    return null;
  }
  const {
    direccion_origen,
    codigo_postal,
    horarios_atencion,
    notas,
    persona_contacto,
    provincia,
    telefono,
    celular,
  } = point;
  const direccionOrigenItem = !direccion_origen ? null : (
    <p className="has-text-weight-normal is-marginless"><span className="has-text-weight-bold">Direcci&oacute;n: </span>{direccion_origen}</p>
  );
  const codigoPostalItem = !codigo_postal ? null : (
    <p className="has-text-weight-normal is-marginless"><span className="has-text-weight-bold">C&oacute;digo Postal: </span>{codigo_postal}</p>
  );
  const horariosAtencionItem = !horarios_atencion ? null : (
    <p className="has-text-weight-normal is-marginless"><span className="has-text-weight-bold">Horarios de Atenci&oacute;n: </span>{horarios_atencion}</p>
  );
  const personaContactoItem = !persona_contacto ? null : (
    <React.Fragment>
      <p className="has-text-weight-normal is-marginless"><span className="has-text-weight-bold">Persona de Contacto: </span>{persona_contacto}</p>
      {!provincia ? null : (<p className="has-text-weight-normal is-marginless"><span className="has-text-weight-bold">Provincia: </span>{provincia}</p>)}
      {!telefono && !celular ? null : (<p className="has-text-weight-normal is-marginless"><span className="has-text-weight-bold">Telefonos: </span>{telefono} {celular}</p>)}
    </React.Fragment>
  );
  const notasItem = !notas ? null : (
    <p className="has-text-weight-normal is-marginless"><span className="has-text-weight-bold">Notas: </span>{notas}</p>
  );
  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{point.nombre} {!point.sucursal ? '' : '(Sucursal)'}</p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column is-paddingless">
              {direccionOrigenItem}
              {codigoPostalItem}
              {notasItem}
            </div>
            <div className="column is-paddingless">
              {horariosAtencionItem}
              {personaContactoItem}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointMiniCard;
