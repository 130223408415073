import React, { Component } from 'react';
import { dispatch } from '@rematch/core';
import Icon from '../../../../components/Icon';
import BasicView from './components/BasicView';
import AddressesView from './components/AddressesView';
import ProductCardView from './components/ProductCardView';
import ContactView from './components/ContactView';
import StatusModal from '../../containers/statusModalContainer';
import ProductsEdit from './components/ProductsEdit'
import apiService from '../../../../services/api';
import ConfirmModal from '../../../../components/ConfirmModal';
import UpdateDeliveryModal from './components/UpdateDeliveryModal';

let validateTimeout;
class DeliveryView extends Component {
    constructor(props) {
        super();
        this.props = props;
        this.state = {
            tabName: 'basico',
            statusModal: false,
            editable: null,
            makeQuote: false,
            error: null,
            deliveryToBeDeleted: null,
            deliveryModal: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.loading !== this.props.loading && !this.props.loading) {
            this.setState({ editable: null });
        }
        if (prevState.makeQuote !== this.state.makeQuote && this.state.makeQuote) {
            this.updateCotizacion();
        }
    }

    setEditable = () => {
        this.setState({ editable: { ...this.props.delivery } });
    }

    setUserEditable = () => {
     this.setState({ editable: { ...this.props.delivery } });
     this.setState({deliveryModal: true})
  }

    onEditableChange = ({ target: { value: val, name, type, checked } }) => {
        const value = type === 'checkbox' ? checked : val;
        const newState = { editable: { ...this.state.editable, [name]: value } };
        if (name === 'cp_origen' || name === 'cp_destino') {
            if (validateTimeout) {
                clearTimeout(validateTimeout);
            }
            this.props.setCpLoading(true);
            validateTimeout = setTimeout(() => this.props.validateCode({ key: name, code: value }), 500);
            if (value.length === 4) {
                newState.makeQuote = true;
            }
        }
        this.setState(newState);
    }

    cancelEditable = () => {
        this.setState({ editable: null });
    }

    onSave = () => {
        const { editable } = this.state;
        this.props.saveDelivery({ delivery: { ...editable }, oldDelivery: this.props.delivery });
    }


    onSavePending = () => {
        const {editable} = this.state
        editable.estado_id = 1
        this.props.saveDelivery({ delivery: { ...editable }, oldDelivery: this.props.delivery });
    }

    tabChanger = (tabName) => {
        return () => {
            this.setState({ tabName });
        };
    }

    tabActiveClass = (tabName) => {
        return tabName === this.state.tabName ? 'is-active' : '';
    }

    openModal = () => {
        this.setState({ statusModal: true });
    }

    closeModal = () => {
        this.setState({ statusModal: false });
    }

    changeStatus = (status) => {
        const deliveryId = this.props.delivery.id;
        const statusId = status.id;
        if (window.confirm('Esta seguro de cambiar el estado de esta entrega')) {
            dispatch.delivery.updateDeliveryStatus({ deliveryId, statusId });
            this.setState({ statusModal: false });
        }
    }

    updateCotizacion = async () => {
        const { editable } = this.state;
        const { envios_detalles: productos, ...quote } = editable;
        const { cotizacion } = await apiService.delivery.quote({ ...quote, productos }, false);
        const newEditable = { ...editable, cotizacion };
        this.setState({ editable: newEditable, makeQuote: false });
    }

    onNewProduct = ({ product }) => {
        const { editable } = this.state;
        const products = [...editable.envios_detalles, product];
        const newEditable = { ...editable, envios_detalles: products };
        this.setState({ editable: newEditable, makeQuote: true });
    }

    onEditProduct = ({ product, index }) => {
        const { editable } = this.state;
        const products = [...editable.envios_detalles];
        products[index] = product;
        const newEditable = { ...editable, envios_detalles: products };
        this.setState({ editable: newEditable, makeQuote: true });
    }

    onRemoveProduct = ({ index }) => {
        const { editable } = this.state;
        const products = editable.envios_detalles.filter((item, detalleIndex) => index !== detalleIndex);
        const newEditable = { ...editable, envios_detalles: products };
        this.setState({ editable: newEditable, makeQuote: true });
    }

    closeDeleteModal = () => {
        this.setState({ deliveryToBeDeleted: null });
    }
    openDeleteModal = () => {
        this.setState({ deliveryToBeDeleted: this.props.delivery.id });
    }

    confirmDeliveryDelete = (deliveryId) => {
      this.props.removeDelivery(deliveryId);
      this.setState({ deliveryToBeDeleted: null });
      this.props.history.push('/deliveries');
    }

    _handleDismissErrorModal = () => {
        this.setState({ error: false });
    }


    deleteDelivery = () => {
      this.props.removeDelivery(this.props.delivery.id);
      this.props.history.push('/deliveries');
    }

    getButtons = () => {
     if (!this.props.role && this.props.delivery.estado_id == 0) {

     return (
        <div className="field is-grouped view-buttons is-pulled-right">
            <p className="control">
              <button className={`button is-small ${!this.props.loading ? '' : 'is-loading'}`}
                      onClick={this.setUserEditable} title="Editar" >
                <Icon key="edit" icon="fas fa-edit" />
              </button>
            </p>
          </div>
      ) } 
      if(!this.state.editable && this.props.role){
        return (
          <div className="field is-grouped view-buttons is-pulled-right">
            <p className="control">
              <button className="button is-small" onClick={this.setEditable} title="Editar" >
                <Icon key="edit" icon="fas fa-edit" />
              </button>
            </p>
          </div>
      ) } if(this.state.editable && this.props.role)  { return (
          <div className="field is-grouped view-buttons is-pulled-right">
            <p className="control">
              <button
                className={`button is-small is-success ${!this.props.loading ? '' : 'is-loading'}`}
                onClick={this.onSave}
                disabled={Object.keys(this.props.cpError).length || this.props.cpLoading}
              >
                <Icon key="save" icon="fas fa-check" />
              </button>
            </p>
            <p className="control">
              <button
                className={`button is-small ${!this.props.loading ? '' : 'is-loading'}`}
                onClick={this.cancelEditable}
              >
                <Icon key="cancel" icon="fas fa-times" />
              </button>
            </p>
          </div>
      );
    }
  }

    render() {
      if(!this.props.delivery) {return null;}
      
            const { delivery, status, statuses, role, loading, originPoint, destinyPoint, cpError, cpLoading } = this.props;
            const { tabName, statusModal, editable, deliveryToBeDeleted, error, deliveryModal } = this.state;
            const productsList = editable ?
                null :
                !delivery ?
                null :
                delivery.envios_detalles.map(product => (
                    <div className="column is-4" key={product.id}>
                      <ProductCardView product={product} />
                    </div>
                ));
            const tabs = {
                basico: (
                    <div className="columns">
                      <div className="column">
                        <BasicView
                          delivery={delivery}
                          editable={editable}
                          loading={loading}
                          status={status}
                          statuses={statuses}
                          onChange={this.onEditableChange}
                        />
                      </div>
                    </div>
                ),
                direcciones: (
                    <div className="columns">
                      <div className="column">
                        <AddressesView
                          delivery={delivery}
                          editable={editable}
                          loading={loading}
                          onChange={this.onEditableChange}
                          type="origen"
                          point={originPoint}
                          cpError={cpError}
                          cpLoading={cpLoading}
                        />
                      </div>
                      <div className="column">
                        <AddressesView
                          delivery={delivery}
                          editable={editable}
                          loading={loading}
                          onChange={this.onEditableChange}
                          type="destino"
                          point={destinyPoint}
                          cpError={cpError}
                          cpLoading={cpLoading}
                        />
                      </div>
                    </div>
                ),
                productos: editable ?
                    (
                      <ProductsEdit
                        products={editable.envios_detalles}
                        onNewProduct={this.onNewProduct}
                        onEditProduct={this.onEditProduct}
                        onRemoveProduct={this.onRemoveProduct}
                      />
                    ) : (
                        <div className="columns is-multiline">
                          {productsList}
                        </div>
                    ),
                contactos: (
                    <div className="columns">
                      <div className="column">
                        <ContactView
                          delivery={delivery}
                          title="Contacto"
                          editable={editable}
                          loading={loading}
                          onChange={this.onEditableChange}
                        />
                      </div>
                    </div>
                )
            };
            const tab = tabs[tabName];
            const modal = !statusModal ? null : (
                <StatusModal onStatusSelect={this.changeStatus} closeModal={this.closeModal} />
            );
            const buttons = this.getButtons();
            const backButton =  (
                <span className="view-buttons is-pulled-left">
                  <button
                    title="Atras"
                    className="button is-small"
                    onClick={() => this.props.history.push('/deliveries')}
                  >
                    <Icon icon="fas fa-arrow-left" />
                  </button>
                </span>
            );
            const deleteButton = !role ?  null : (
                <div className="field is-grouped view-buttons is-pulled-right">
                  <p className="control">
                  <button
                    onClick={this.openDeleteModal}
                    className="button is-small is-danger"
                    title="Eliminar"   >
                    <Icon key="trash" icon="fas fa-trash" />
                  </button>
                  </p>
                  </div>);
        return(
          <React.Fragment>
            { deliveryModal && <UpdateDeliveryModal 
              delivery={delivery} 
              onSave={this.onSavePending} 
              closeModal={()=> this.setState({deliveryModal: false, editable: false})}
              onChange={this.onEditableChange}
              onDelete={this.deleteDelivery}
              />}
            {deliveryToBeDeleted &&
              <ConfirmModal
                title="Confirmaci&oacute;n"
                message="Esta seguro de eliminar esta entrega?"
                onCancel={this.closeDeleteModal}
                onConfirm={() => this.confirmDeliveryDelete(deliveryToBeDeleted)}
              />
            }
            {backButton}
            {deleteButton}
            {buttons}
            <section className="section">
              {modal}
              <h1 className="title is-1 has-text-centered">Entrega</h1>
              { error &&
                <div class="notification is-danger">
                  <button class="delete" onClick={this._handleDismissErrorModal}></button>
                  Ocurrió un error al realizar la operación solicitada. Si el error persiste contacte a los administradores.
                </div>

              }

              <div className="box is-marginless">
                <div className="tabs is-centered">
                  <ul>
                    <li className={this.tabActiveClass('basico')} onClick={this.tabChanger('basico')} style={{ marginTop: '0.25em' }}><a>Basico</a></li>
                    <li className={this.tabActiveClass('direcciones')} onClick={this.tabChanger('direcciones')}><a>Direcciones</a></li>
                    <li className={this.tabActiveClass('productos')} onClick={this.tabChanger('productos')}><a>Productos</a></li>
                    <li className={this.tabActiveClass('contactos')} onClick={this.tabChanger('contactos')}><a>Contactos</a></li>
                  </ul>
                </div>
                {tab}
              </div>
            </section>
            {backButton}
            {deleteButton}
          {buttons}
          </React.Fragment>
        );
    }
}

export default DeliveryView;
