import update from 'immutability-helper';

export const state = {
  products: {},
  editableProduct: null,
  search: '',
  loading: false,
};

export const reducers = {
  updateProducts(state, data) {
    const products = data.reduce((acum, product) => ({
      ...acum, 
      [product.id]: product,
    }), {});
    return update(state, {
      products: { $merge: products },
    });
  },
  removeProduct(state, productId) {
    return update(state, {
      products: {
        $apply: function(obj) {
          const copyproducts = {...obj};
          delete copyproducts[productId];
          return copyproducts;
        },
      },
    });
  },
  setEditableProduct(state, product) {
    return update(state, {
      editableProduct: { $set: product },
    });
  },
  setSearch(state, value) {
    return update(state, { search: { $set: value } });
  },
  setLoading(state, value) {
    return update(state, { loading: { $set: value } });
  },
};
