import { connect } from 'react-redux';
import App from '../App';

const mapStateToProps = state => {
  return {
    logged: state.auth.logged,
    rol: state.auth.rol,
  };
};

const appContainer = connect(mapStateToProps)(App);

export default appContainer;
