import { createSelector } from 'reselect';
import { filter } from '../../utils/helpers';

export const points = state => state.points;
export const pointById = (state, { pointId }) => state.points[pointId];
export const search = state => state.search;
export const loading = state => state.loading;
export const cpError = state => state.cpError;
export const cpLoading = state => state.cpLoading;

export const filteredPoints = createSelector(
  points,
  search,
  (points, search) => Object.values(points)
    .filter(filter(search, ['id', 'usuario_id']))
    .sort((a, b) => {
      if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
        return -1;
      }
      if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
        return 1;
      }
      return 0;
    }),
);
