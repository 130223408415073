import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class AuthApi {
  static async resetPassword({ current: old_password, change: new_password}) {
    const response = await axios.post(`${apiUrl}/auth/resetPassword`, { old_password, new_password }, authHeader());
    return response.data;
  }
  static async getToken(userId) {
    const response = await axios.post(`${apiUrl}/auth/getToken`, { userId}, authHeader());
    return response.data;
  }
}

export default AuthApi;
