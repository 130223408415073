import React from 'react';
import "./userForm.css";

const UserForm = ({ user, lists, closeModal, setEditableUser, updateUser }) => {
  const listOptions = Object.values(lists).map(list => (
    <option value={list.id} key={list.id}>{list.codigo}</option>
  ));
  const passwordField = !user || user.id ? null : (
    <div className="field">
      <label className="label">Password</label>
      <div className="control">
        <input
          className="input"
          type="password"
          placeholder="Password..."
          value={user.password || ''}
          onChange={({ target }) => setEditableUser({ ...user, password: target.value })}
        />
      </div>
    </div>
  );
  console.log(user);
  return !user ? null : (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-content">
        <div id = "menuUser">
          <div className="field">
            <label className="label">Usuario/Nombre de Fantasia</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Usuario..."
                value={user.usuario || ''}
                onChange={({ target }) => setEditableUser({ ...user, usuario: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
                className="input"
                type="email"
                placeholder="Email..."
                value={user.email || ''}
                onChange={({ target }) => setEditableUser({ ...user, email: target.value })}
              />
            </div>
          </div>
          {passwordField}
          <div className="field">
            <label className="label">Razon Social</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Razon social..."
                value={user.razon_social || ''}
                onChange={({ target }) => setEditableUser({ ...user, razon_social: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Telefono</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Telefono..."
                value={user.telefono || ''}
                onChange={({ target }) => setEditableUser({ ...user, telefono: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Condici&oacute;n de IVA</label>
            <div className="control">
              <div className="select">
                <select
                  value={user.datoImpositivo}
                  onChange={({ target }) => setEditableUser({ ...user, datoImpositivo: target.value })}
                >
                  <option>Responsable No Inscrito</option>
                  <option>Exento</option>
                  <option>Responsable Inscrito</option>
                  <option>Monotributista</option>
                  <option>Consumidor Final</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">CUIT</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Formato XX-XXXXXXXX- X"
                value={user.cuit || ''}
                onChange={({ target }) => setEditableUser({ ...user, cuit: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Domicilio</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Domicilio..."
                value={user.domicilio || ''}
                onChange={({ target }) => setEditableUser({ ...user, domicilio: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Codigo Postal</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Codigo postal..."
                value={user.codigo_postal || ''}
                onChange={({ target }) => setEditableUser({ ...user, codigo_postal: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Provincia</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Provincia..."
                value={user.provincia || ''}
                onChange={({ target }) => setEditableUser({ ...user, provincia: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Rubro</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Rubro..."
                value={user.rubro || ''}
                onChange={({ target }) => setEditableUser({ ...user, rubro: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Condiciones de pago</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Condiciones de pago..."
                value={user.condicion_pago || ''}
                onChange={({ target }) => setEditableUser({ ...user, condicion_pago: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Lista de precio</label>
            <div className="control">
              <div className="select">
                <select
                  value={user.lista_precios}
                  onChange={({ target }) => setEditableUser({ ...user, lista_precios: target.value })}
                  >
                    {listOptions}
                  </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">Sitio Web</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Sitio Web..."
                value={user.sitio_web || ''}
                onChange={({ target }) => setEditableUser({ ...user, sitio_web: target.value })}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Redes</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Redes, separados por comas (,)..."
                value={user.redes || ''}
                onChange={({ target }) => setEditableUser({ ...user, redes: target.value })}
              />
            </div>
          </div>
          <div className="control">
            <label className="label">Venta por Internet</label>
            <label className="radio">
              <input
                type="radio"
                name="answer"
                value={true}
                checked={!!user.venta_internet}
                onChange={({ target }) => setEditableUser({ ...user, venta_internet: true })}
              />
              Si
            </label>
            <label className="radio">
              <input
                type="radio"
                name="answer"
                value={false}
                onChange={({ target }) => setEditableUser({ ...user, venta_internet: false })}
                checked={!user.venta_internet}
              />
              No
            </label>
          </div>
            <div className="field is-grouped">
              <div className="control">
                <button className="button is-link" onClick={() => updateUser(user)}>
                  {!user.id ? 'Crear' : 'Editar'}
                </button>
              </div>
              <div className="control">
                <button className="button is-text" onClick={closeModal}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeModal} />
      </div>
    );
  };

  export default UserForm;
