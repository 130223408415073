import { connect } from 'react-redux';
import { select } from '@rematch/select';
import  SelectProduct from '../SelectProduct';

const mapStateToProps = state => {
  const products =  select.masterProduct.products(state);
  return {
    products
  };
};

const SelectProductsContainer = connect(mapStateToProps)(SelectProduct);

export default SelectProductsContainer;
