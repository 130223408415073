import { connect } from 'react-redux';
import PointView from '../components/PointView';
import { select } from '@rematch/select';

const mapStateToProps = (state, { match: { params: { id: pointId } } }) => {
  return {
    point: select.frequentPoint.pointById(state, { pointId }),
    loading: select.frequentPoint.loading(state),
    cpError: select.frequentPoint.cpError(state),
    cpLoading: select.frequentPoint.cpLoading(state),
  };
};

const mapDispatchToProps = ({ frequentPoint }) => {
  return {
    updatePoint: point => {
      frequentPoint.savePoint(point);
    },
    removePoint(pointId) {
      frequentPoint.removePointEffect(pointId);
    },
    validateCode(code) {
      frequentPoint.validateCode(code);
    },
    setCpLoading(value) {
      frequentPoint.setCpLoading(value);
    },
  };
};

const pointViewContainer = connect(mapStateToProps, mapDispatchToProps)(PointView);

export default pointViewContainer;