import React from 'react';
import "./listForm.css";

const ListForm = ({list, closeModal, setEditableList, updateList}) => {
  return !list ? null : (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}/>
      <div className="modal-content">
        <div id="menuList">
          <div className="field">
            <label className="label">C&oacute;digo</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Codigo..."
                value={list.codigo}
                onChange={({target}) => setEditableList({...list, codigo: target.value})}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Precio distancia</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Precio distancia..."
                value={list.precio_distancia}
                onChange={({target}) => setEditableList({...list, precio_distancia: target.value})}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Precio volumen</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Precio volumen..."
                value={list.precio_volumen}
                onChange={({target}) => setEditableList({...list, precio_volumen: target.value})}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Precio piso</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Precio piso..."
                value={list.precio_piso}
                onChange={({target}) => setEditableList({...list, precio_piso: target.value})}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Precio peso</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Precio peso..."
                value={list.precio_peso}
                onChange={({target}) => setEditableList({...list, precio_peso: target.value})}
              />
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-link" onClick={() => updateList(list)}>
                {!list.id ? 'Crear' : 'Editar'}
              </button>
            </div>
            <div className="control">
              <button className="button is-text" onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={closeModal}/>
    </div>
  );
};

export default ListForm;
