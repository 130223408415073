import React, { Component } from 'react';
import DeliveryItem from '../DeliveryItem';
import SearchInput from '../../../../components/SearchInput';
import DateRangeModal from '../../../../components/DateRangeModal';
import FilterModal from '../../../../components/FilterModal';
import Icon from '../../../../components/Icon';
import DeliveryModal from './components/DeliveryModal/DeliveryModal'
import MercadoPagoModal from './components/MercadoPagoModal/MercadoPagoModal';
import api from '../../../../services/api/';
import { dispatch } from '@rematch/core';




class TableDeliveries extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { dateRangeModal: false, filterModal: false, deliveryModal: false, mercadoPagoModal: false };
    this.resizeWindow = this.resizeWindow.bind(this);
    this.openDateRangeModal = this.openDateRangeModal.bind(this);
    this.closeDateRangeModal = this.closeDateRangeModal.bind(this);
    this.onDateRangeChange = this.onDateRangeChange.bind(this);
    this.openFilterModal = this.openFilterModal.bind(this);
    this.closeFilterModal = this.closeFilterModal.bind(this);
  }

  resizeWindow() {
    this.div.style.width = `${this.box.offsetWidth - 40}px`;
    this.box.style.height = `${this.div.offsetHeight + 40}px`;
  }

  idS = new Set()


  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);
    this.resizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  componentDidUpdate() {
    this.resizeWindow();
  }

  openDateRangeModal() {
    this.setState({ dateRangeModal: true });
  }

  closeDateRangeModal() {
    this.setState({ dateRangeModal: false });
  }

  onDateRangeChange({ startDate, endDate }) {
    this.props.fetchDeliveriesByRange({ startDate, endDate });
    this.closeDateRangeModal();
  }

  openFilterModal() {
    this.setState({ filterModal: true });
  }

  closeFilterModal() {
    this.setState({ filterModal: false });
  }

  edition = () => {
    if (this.idS.size) {
      this.setState({ deliveryModal: true })
    }
  }

  mercadoPagoModal = () => {
    if (this.idS.size) {
      this.setState({ mercadoPagoModal: true })
    }
  }


  onEditableChange = ({ target: { value: val, name, type, checked } }) => {
    const value = type === 'checkbox' ? checked : val;

    if (value) {
      this.idS.add(name)
    }
    else {
      this.idS.delete(name)
    }
  }

  onSave = (obj) => {
    obj.ids = [...this.idS];
    const request = api.delivery.massiveUpdate(obj)
    this.setState({ deliveryModal: false })
    dispatch.delivery.fetchDeliveries();
    this.idS.clear();
  }

  onAccepMp = async () => {
    console.log("onAccepMp")
    const array = [...this.idS]
    console.log(this.idS)
    console.log(array)
    const request = await api.payment.getPreferencePayment({ ids: array })
    if (request.status === 200) {
      window.open(request.data.init_point, "_self");
    }
  }


  render() {
    const {
      deliveries,
      filters,
      labels,
      filtersSelected,
      addFilterSelected,
      removeFilterSelected,
      clearFiltersSelected,
      setSearch,
      match,
      history,
      search,
      startDate,
      endDate,
      rol,
      statuses
    } = this.props;
    const { dateRangeModal, filterModal } = this.state;
    const { url } = match;
    const rows = deliveries.map(delivery =>
      <DeliveryItem
        delivery={delivery}
        key={delivery.id}
        url={url}
        history={history}
        rol={rol}
        onChange={this.onEditableChange}
      />
    );
    const dateRangeModalComponent = !dateRangeModal ? null : (
      <DateRangeModal
        onOk={this.onDateRangeChange}
        onCancel={this.closeDateRangeModal}
        startDate={startDate}
        endDate={endDate}
      />
    );
    if (!rol) {
      delete filters.usuario;
    }
    const filterModalComponent = !filterModal ? null : (
      <FilterModal
        filters={filters}
        filtersLabel={labels}
        filtersSelected={filtersSelected}
        onSelect={addFilterSelected}
        onDeselect={removeFilterSelected}
        onClearAll={clearFiltersSelected}
        onClose={this.closeFilterModal}
      />
    );
    const clienteCol = !rol ? null : <th>Cliente</th>;
    return (
      <section className="section" style={{ margin: '2px', padding: '2px' }}>
        {dateRangeModalComponent}
        {filterModalComponent}
        <h1 className="title is-3 has-text-centered">Mis Entregas</h1>
        {this.state.deliveryModal && <DeliveryModal save={(obj) => this.onSave(obj)} statuses={statuses} closeModal={() => this.setState({ deliveryModal: false })} />}
        {this.state.mercadoPagoModal && <MercadoPagoModal save={() => this.onAccepMp()} closeModal={() => this.setState({ mercadoPagoModal: false })} />}
        <div className="columns">
          <div className="column">
            <SearchInput size="small" value={search} onChange={setSearch} />
          </div>
          <div className="column is-one-third">
            <button className="button is-normal is-fullwidth" onClick={this.openDateRangeModal}>Rango Fechas</button>
          </div>
          <div className="column is-one-third">
            <button className="button is-normal is-fullwidth" onClick={this.openFilterModal}>Filtros</button>
          </div>
        </div>
        <div className="box is-marginless" style={{ padding: '5px' }} ref={div => { this.box = div; }}>
          <div style={{ width: '200px', overflowY: "auto", position: 'absolute' }} ref={div => { this.div = div; }}>
            <table className="table is-narrow">
              <thead style={{ fontSize: '14px' }}>
                <tr>
                  <th><button className="button is-small" onClick={rol ? this.edition : this.mercadoPagoModal} >
                    <Icon key="edit" className="is-large" icon={rol ? "fas fa-edit" : "far fa-handshake"} />
                  </button></th>
                  <th>Nro Entrega</th>
                  {clienteCol}
                  <th>Fecha Creacion</th>
                  <th>Fecha Entrega</th>
                  <th>Cotizado</th>
                  <th>Volumen m3</th>
                  <th>Cobro cargo trans</th>
                  <th>Factura</th>
                  <th>Remito</th>
                  <th>Origen</th>
                  <th>Destino</th>
                  <th>Estado</th>
                  <th>Fecha Disponibilidad</th>
                  <th>Peso</th>
                  <th>Observaciones</th>
                  <th>Ayudante</th>
                  <th>Estado Pago</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: '13px' }}>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }
}

export default TableDeliveries;
