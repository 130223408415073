import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PointItem from '../PointItem';
import PointForm from '../../containers/pointFormContainer';
import Icon from '../../../../components/Icon';
import SearchInput from '../../../../components/SearchInput';

class TablePoints extends Component {
  constructor(props) {
    super();
    this.props = props;
  }

  componentDidMount() {
    this.props.fetchPoints();
  }

  render() {
    const { points, setSearch, rol } = this.props;
    const { match, history } = this.props;
    const { url } = match;
    const rows = points.map(point =>
      <PointItem
        point={point}
        key={point.id}
        url={url}
        history={history}
        rol={rol}
      />
    );
    const clienteCol = !rol ? null : <th>Cliente</th>;
    return (
      <div className="section">
        <h1 className="title is-1 has-text-centered">Puntos Frecuentes</h1>
        <Link
          className="button is-primary is-large is-rounded"
          to="/points/new"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            width: '55px',
            borderRadius: '100%'
          }}
        >
          <Icon icon="fas fa-plus" />
        </Link>
        <PointForm />
        <SearchInput onChange={setSearch} />
        <div className="box is-marginless">
          <table className="table is-narrow is-hoverable is-fullwidth">
            <thead>
            <tr>
              <th>Nombre</th>
              <th>Sucursal</th>
              <th>Direcci&oacute;n</th>
              <th>C&oacute;digo Postal</th>
                {clienteCol}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

                                                                                                            export default TablePoints;
