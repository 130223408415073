import React, { Component } from 'react';
import ProductModal from '../../../../../Quote/components/Products/components/ProductModal';
import ProductCard from '../../../../../Quote/components/Products/components/ProductCard';
import { productTemplate } from '../../../../../../modules/quote/templates';

class ProductsEdit extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { editableProduct: null, index: null };
    this.newProduct = this.newProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  saveProduct(product) {
    const { index } = this.state;
    if (index === null) {
      // reducer action
      this.props.onNewProduct({ product });
    } else {
      // reducer action
      this.props.onEditProduct({ product, index });
    }
    this.closeModal();
  }

  closeModal() {
    this.setState({ editableProduct: null, index: null });
  }

  newProduct() {
    this.setState({ editableProduct: { ...productTemplate }, index: null })
  }

  editProduct({ product, index }) {
    this.setState({ editableProduct: { ...product }, index });
  }

  removeProduct({ index }) {
    this.props.onRemoveProduct({ index });
  }

  render() {
    const { products } = this.props;
    const { editableProduct } = this.state;
    const modal = !editableProduct ? null : (
      <ProductModal
        product={editableProduct}
        onSave={this.saveProduct}
        closeModal={this.closeModal}
      />
    );
    const productsList = products.map((product, index) => (
      <div className="column is-4" key={index}>
        <ProductCard
          index={index}
          product={product}
          onEditClick={this.editProduct}
          onRemoveClick={this.removeProduct}
        />
      </div>
    ));
    productsList.push((
      <div className="column is-4" key={productsList.length}>
        <button
          className="button is-success is-fullwidth"
          onClick={this.newProduct}
        >
          Agregar
        </button>
      </div>
    ));
    return (
      <React.Fragment>
        {modal}
        <div className="columns is-multiline">
          {productsList}
        </div>
      </React.Fragment>
    );
  }
}

export default ProductsEdit;
