import { connect } from 'react-redux';
import UserForm from '../components/UserForm';

const mapStateToProps = state => ({
  user: state.user.editableUser,
  lists: state.priceList.lists,
});

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch.user.setEditableUser(null);
    },
    setEditableUser: user => {
      dispatch.user.setEditableUser(user);
    },
    updateUser: user => {
      dispatch.user.saveUser(user);
    },
  };
};

const userFormContainer = connect(mapStateToProps, mapDispatchToProps)(UserForm);

export default userFormContainer;
