import React, { Component } from 'react';
import SearchInput from '../../../../components/SearchInput';

class StatusModal extends Component {
  constructor(props) {
    super();
    this.props = props;
  }
  componentDidMount() {
    // via container
    this.props.fetchStatus();
  }


  render() {
    const {
      // via container
      statuses,
      search,
      setSearch,
      // via props
      closeModal,
      onStatusSelect,
    } = this.props;
    const statusesList = statuses.map(status => (
      <li key={status.id} onClick={() => onStatusSelect(status)}>
        <a>{status.estado}</a>
      </li>
    ));
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-content">
          <div className="box">
            <h1 className="title">Cambio de Estado</h1>
            <SearchInput value={search} onChange={setSearch} size />
            <aside className="menu">
              <ul className="menu-list">
                {statusesList}
              </ul>
            </aside>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeModal} />
      </div>
    );
  }
}

export default StatusModal;
