import React from 'react';
import Icon from '../../../../../../components/Icon';
import HorizontalField from '../../../../../../components/HorizontalField';

const ContactFields = ({ contact: { nombre, email, telefono, whatsapp }, onChange, errors, override = null }) => {
  const nombreVal = override ? override.persona_contacto : nombre.value;
  const telefonoVal = override ? override.telefono : telefono.value;
  const whatsappVal = override ? override.celular : whatsapp.value;
  return (
    <React.Fragment>
      <HorizontalField
        label="Nombre"
        placeholder="Nombre del contacto..."
        name={nombre.key}
        controlClassName="has-icons-left"
        icon={<Icon icon="fas fa-user" className="is-small is-left" />}
        value={nombreVal}
        onChange={onChange}
        error={errors[nombre.key]}
        disabled={override}
      />
      <HorizontalField
        label="Email"
        type="email"
        placeholder="Email del contacto..."
        controlClassName="has-icons-left"
        icon={<Icon icon="fas fa-envelope" className="is-small is-left" />}
        name={email.key}
        value={email.value}
        onChange={onChange}
        disabled={override}
      />
      <HorizontalField
        label="Telefono"
        placeholder="Telefono del contacto..."
        controlClassName="has-icons-left"
        icon={<Icon icon="fas fa-phone" className="is-small is-left" />}
        name={telefono.key}
        value={telefonoVal}
        onChange={onChange}
        disabled={override}
      />
      <HorizontalField
        label="Whatsapp"
        placeholder="Numero de whatsapp del contacto..."
        controlClassName="has-icons-left"
        icon={<Icon icon="fas fa-mobile-alt" className="is-small is-left" />}
        name={whatsapp.key}
        value={whatsappVal}
        onChange={onChange}
        disabled={override}
      />
    </React.Fragment>
  );
};

export default ContactFields;
