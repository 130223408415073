import { connect } from 'react-redux';
import { select } from '@rematch/select';
import TableDeliveries from '../components/TableDeliveries';

const mapStateToProps = state => {
  const status = select.status.status(state);
  const statuses = select.status.status(state);
  const { filters, labels } = select.delivery.filters(state, status);
  return {
    deliveries: select.delivery.filteredDeliveries(state, status),
    filters,
    labels,
    filtersSelected: select.delivery.filtersSelected(state),
    search: select.delivery.search(state),
    startDate: select.delivery.startDate(state),
    endDate: select.delivery.endDate(state),
    rol: state.auth.rol,
    statuses
  };
};

const mapDispatchToProps = ({
  delivery: { fetchDeliveriesByRange, setSearch, clearFiltersSelected, addFilterSelected, removeFilterSelected }
}) => {
  return {
    setSearch({ target: { value } }) {
     setSearch(value);
    },
    fetchDeliveriesByRange,
    clearFiltersSelected,
    addFilterSelected,
    removeFilterSelected,
  };
};

const tableDeliveriesContainer = connect(mapStateToProps, mapDispatchToProps)(TableDeliveries);

export default tableDeliveriesContainer;
