import React, { Component } from 'react';
import Icon from '../../../../components/Icon';
import HorizontalField from '../../../../components/HorizontalField';
import ConfirmModal from '../../../../components/ConfirmModal';

const newPointTemplate = {
  nombre: '',
  direccion_origen: '',
  codigo_postal: '',
};

let validateTimeout;
class PointView extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { editable: null, pointToBeDeleted: null };
    this.setEditable = this.setEditable.bind(this);
    this.cancelEditable = this.cancelEditable.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setPointToBeDeleted = this.setPointToBeDeleted.bind(this);
    this.confirmPointDelete = this.confirmPointDelete.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.field = this.field.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id === 'new') {
      this.setEditable({ editable: { ...newPointTemplate } });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.setState({ editable: null });
    }
  }

  setEditable() {
    this.setState({ editable: { ...this.props.point }});
  }

  cancelEditable() {
    this.setState({ editable: null });
    if (this.props.match.params.id === 'new') {
      this.props.history.push('/points');
    }
  }

  onChange({ target: { name, value: val, checked, type } }) {
    if (name === 'codigo_postal') {
      if (validateTimeout) {
        clearTimeout(validateTimeout);
      }
      this.props.setCpLoading(true);
      validateTimeout = setTimeout(() => this.props.validateCode(val), 500);
    }
    const value = type === 'checkbox' ? checked : val;
    const { editable } = this.state;
    this.setState({ editable: { ...editable, [name]: value } });
  }

  onSave() {
    const { editable } = this.state;
    this.props.updatePoint({ point: { ...editable }, history: this.props.history });
  }

  setPointToBeDeleted() {
    this.setState({ pointToBeDeleted: this.props.point.id });
  }

  confirmPointDelete(pointId) {
    this.props.removePoint(pointId);
    this.setState({ pointToBeDeleted: null });
    this.props.history.push('/points');
  }

  closeDeleteModal() {
    this.setState({ pointToBeDeleted: null });
  }

  field({ label, name, value, error, help }) {
    return (
      <HorizontalField
        label={label}
        name={name}
        value={value}
        error={error}
        help={help}
        onChange={this.onChange}
        editable={!!this.state.editable}
        disabled={this.props.loading}
      />
    );
  }

  render() {
    const Field = this.field;
    const { loading, cpError, cpLoading } = this.props;
    const { editable, pointToBeDeleted } = this.state;
    const point = editable || this.props.point;
    const buttons = !editable ? (
      <div className="field is-grouped view-buttons is-pulled-right">
        <p className="control">
          <button className="button is-small" onClick={this.setEditable} title="Editar">
            <Icon key="edit" icon="fas fa-edit" />
          </button>
        </p>
        <p className="control">
          <button className="button is-small is-danger" onClick={this.setPointToBeDeleted} title="Eliminar">
            <Icon key="trash" icon="fas fa-trash" />
          </button>
        </p>
      </div>
    ) : (
      <div className="field is-grouped view-buttons is-pulled-right">
        <p className="control">
          <button
            className={`button is-small is-success ${!loading ? '' : 'is-loading'}`}
            onClick={this.onSave}
            disabled={cpError || cpLoading}
            title="Guardar"
          >
            <Icon key="save" icon="fas fa-check" />
          </button>
        </p>
        <p className="control">
          <button
            className={`button is-small ${!loading ? '' : 'is-loading'}`}
            onClick={this.cancelEditable}
            title="Cancelar"
          >
            <Icon key="cancel" icon="fas fa-times" />
          </button>
        </p>
      </div>
    );
    const backButton = (
      <span className="view-buttons is-pulled-left">
        <button
          className="button is-small"
          onClick={() => this.props.history.push('/points')}
        >
          <Icon icon="fas fa-arrow-left" />
        </button>
      </span>
    );
    const modal = !pointToBeDeleted ? null : (
      <ConfirmModal
        title="Confirmaci&oacute;n"
        message="Esta seguro de eliminar este punto frecuente?"
        onCancel={this.closeDeleteModal}
        onConfirm={() => this.confirmPointDelete(pointToBeDeleted)}
      />
    );
    const sucursalField = editable
      ? (<div className="field is-horizontal">
          <div className="field-label is-normal" />
          <div className="field-body">
            <label className="checkbox">
              <input
                type="checkbox"
                name="sucursal"
                checked={editable.sucursal}
                disabled={loading}
                onChange={this.onChange}
                style={{ marginRight: '5px' }}
              />
                Sucursal
            </label>
          </div>
        </div>)
      : !point || !point.sucursal
      ? null
      : (<div className="field is-horizontal">
          <div className="field-label is-normal" />
          <div className="field-body">
            Es sucursal
          </div>
        </div>);
    return !point ? null : (
      <React.Fragment>
        {modal}
        {backButton}
        {buttons}
        <section className="section" style={{ paddingBottom: '5px' }}>
          <h1 className="title is-1 has-text-centered">Punto Frecuente</h1>
          <div className="box">
            <div className="columns">
              <div className="column">
                <Field
                  label="Nombre"
                  name="nombre"
                  value={point.nombre}
                />
                {sucursalField}
                <Field
                  label="C&oacute;digo Postal"
                  name="codigo_postal"
                  value={point.codigo_postal}
                  error={!cpError || cpLoading ? null : 'Codigo postal no encontrado'}
                  help={!cpLoading ? null : 'Validando codigo postal...'}
                />
                <Field
                  label="Direcci&oacute;n"
                  name="direccion_origen"
                  value={point.direccion_origen}
                />
                <Field
                  label="Horarios de atenci&oacute;n"
                  name="horarios_atencion"
                  value={point.horarios_atencion}
                />
                <Field
                  label="Persona de contacto"
                  name="persona_contacto"
                  value={point.persona_contacto}
                />
                <Field
                  label="Telefono"
                  name="telefono"
                  value={point.telefono}
                />
                <Field
                  label="Celular"
                  name="celular"
                  value={point.celular}
                />
                <Field
                  label="Provincia"
                  name="provincia"
                  value={point.provincia}
                />
                <Field
                  label="Notas"
                  name="notas"
                  value={point.notas}
                />
              </div>
            </div>
          </div>
        </section>
        {backButton}
        {buttons}
      </React.Fragment>
    );
  }
}

export default PointView;
