import React, { Component } from 'react';
import HorizontalField from '../../../../../../components/HorizontalField';

class UpdateDeliveryModal extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { delivery: { ...this.props.delivery } };
    this.closeModal = this.closeModal.bind(this);
    this.saveProduct = this.saveProduct.bind(this);

  }

  saveProduct() {
    this.props.onSave();
    this.closeModal();
  }

  closeModal() {
    this.props.closeModal();
  }



  render() {
    const { delivery } = this.state;
    const { onChange,onDelete } = this.props;
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title is-marginless">Confirmar o Eliminar entrega borrador</p>
            <button className="delete" aria-label="close" onClick={this.closeModal} />
          </header>
          <section className="modal-card-body">
            <HorizontalField
              label="Nombre"
              placeholder="Nombre..."
              type="text"
              name={"contacto"}
              defaultValue={delivery.contacto}
              onChange={onChange}
            />
            <HorizontalField
              label="Email"
              placeholder="Email..."
              type="text"
              name={"contacto_mail"}
              defaultValue={delivery.contacto_mail}
              onChange={onChange}
            />
            <HorizontalField
              label="Telefono"
              placeholder="Telefono..."
              type="text"
              name={"contacto_telefono"}
              defaultValue={delivery.contacto_telefono}
              onChange={onChange}
            />
            <HorizontalField
              label="Whatsaap"
              placeholder="Whatsaap..."
              type="text"
              name={"contacto_whatsapp"}
              defaultValue={delivery.contacto_whatsapp}
              onChange={onChange}
            />
            <HorizontalField
              label="Observación"
              placeholder="Observaciones..."
              type="text"
              name={"observaciones"}
              defaultValue={delivery.observaciones}
              onChange={onChange}
            />
          </section>
          <footer className="modal-card-foot">
            <div style={{ textAlign: 'center', width: '100%' }}>
              <button className="button is-success" onClick={this.saveProduct}>Confirmar</button>
              <button className="button" onClick={onDelete}>Eliminar</button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default UpdateDeliveryModal;
