import React from 'react';
import ErrorMessage from '../ErrorMessage';
import './horizontalField.css';

const HorizontalField = ({
  name,
  label,
  placeholder,
  icon = null,
  controlClassName = '',
  type = 'text',
  value,
  checked,
  onChange,
  error,
  min,
  max,
  help,
  editable = true,
  disabled = false,
  defaultValue
}) => {
  const helpMessage = !help ? null : (
    <p className="help">{help}</p>
  );
  const content = !editable ? (
    <div className="field-value">
      <span>{value}</span>
    </div>
  ) : (
    <div className="field">
      <div className={`control ${controlClassName}`}>
        <input
          name={name || label}
          className={`input ${!error ? '' : 'is-danger'}`}
          type={type}
          value={value}
          defaultValue={defaultValue}
          checked={checked}
          placeholder={placeholder}
          onChange={onChange}
          min={min}
          max={max}
          disabled={disabled}
        />
        {icon}
      </div>
      {helpMessage}
      <ErrorMessage message={error} show={!!error} />
    </div>
  );
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label || name}</label>
      </div>
      <div className="field-body">
        {content}
      </div>
    </div>
  );
};

export default HorizontalField;
