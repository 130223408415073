import React, { Component } from 'react';
import Icon from '../../../../components/Icon';
import ProductModal from './components/ProductModal';
import ProductCard from './components/ProductCard';
import { productTemplate } from '../../../../modules/quote/templates';
import { dispatch } from "@rematch/core";

class Products extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { editableProduct: null, index: null };
    this.closeModal = this.closeModal.bind(this);
    this.newProduct = this.newProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.makeQuote = this.makeQuote.bind(this);

    this.state = {
      aditionalWeight: false
    };
  }

  inputHandler = ({ target: { name: key, value: val, type, checked } }) => {
    const value = type === 'checkbox' ? checked : val;
    this.props.onInputChange({ key, value });
  }

  componentDidMount() {
    this.makeQuote();
  }

  componentDidUpdate(previousProps) {

    if (previousProps.products.length !== this.props.products.length || previousProps.makeQuote !== this.props.makeQuote) {
      this.makeQuote();
    }
  }

  getAditionalWeight = () => {
    let flag = false;
        for (let product of this.props.products) {
            if (product.peso > 30 || product.alto > 150 || product.ancho > 150 || product.profundo > 150 ) {
              flag = true;
              break;
              }
            }
    return flag        
  }

  makeQuote() {

    this.setState({aditionalWeight: this.getAditionalWeight()})
    const { quote, products } = this.props;
    this.props.setAmount(null);
    if (products && products.length) {
      dispatch.quote.quote({ quote: { ...quote, productos: products }, save: false });
    }
  }

  closeModal() {
    this.setState({ editableProduct: null, index: null });
  }

  newProduct() {
    this.setState({ editableProduct: { ...productTemplate }, index: null })
  }

  editProduct({ product, index }) {
    console.log("edito")
    this.setState({ editableProduct: { ...product }, index });
  }

  removeProduct({ index }) {
    this.props.removeProduct(index);
    if (this.getAditionalWeight()) {
      const key = 'aditionalWeight'
      const value = false
      this.props.onInputChange({ key, value });
    }
  }

  saveProduct(product) {
    const { index } = this.state;
    if (index === null) {
      // reducer action
      this.props.newProduct(product);
      this.props.setErrors([]);
    } else {
      // reducer action
      this.props.editProduct({ product, index });
    }
    if (this.getAditionalWeight()) {
      const key = 'aditionalWeight'
      const value = false
      this.props.onInputChange({ key, value });
    }
    this.closeModal();
  }

  render() {
    const { products, errors, amount,quote } = this.props;
    const { editableProduct } = this.state;

    const modal = !editableProduct ? null : (
      <ProductModal product={editableProduct} onSave={this.saveProduct} closeModal={this.closeModal} />
    );
    const productsList = products.map((product, index) => (
      <div className="column is-4" key={index}>
        <ProductCard
          index={index}
          product={product}
          onEditClick={this.editProduct}
          onRemoveClick={this.removeProduct}
        />
      </div>
    ));
    productsList.push((
      <div className="column is-4" key={productsList.length}>
        <button
          className="button is-success is-fullwidth"
          onClick={this.newProduct}
        >
          <Icon icon="fas fa-plus" />
        </button>
      </div>
    ));
    const errorMessage = !errors.products ? null : (
      <article className="message is-danger">
        <div className="message-body">
          {errors.products}
        </div>
      </article>
    );
    const quoteResult = !products.length ? null
    : !amount && amount !== 0 ? (
      <article className="message is-dark">
        <div className="message-body has-text-centered">
          Calculando cotizaci&oacute;n...
        </div>
      </article>
    ) : (
      <article className="message is-dark">
        <div className="message-body has-text-centered">
          Monto cotizado: ${amount}
        </div>
      </article>
    );
    return (
      <div className="content">
        {modal}
        <h1 className="title is-centered">Datos de la carga</h1>
        {errorMessage}
        <div className="columns is-multiline is-mobile">
          {productsList}
        </div>
        { this.state.aditionalWeight?<div>
        <input type="checkbox"
        name="aditionalWeight"
        checked={quote.aditionalWeight}
        onChange={this.inputHandler}/>
        <span className="icon has-text-warning">
        <i className="fas fa-exclamation-triangle"></i>
        </span>
        <span>
         Su entrega contiene productos mayores de 30 KG o 1.5 metros de longitud. Cotizar ayudante.
        </span>
        </div>: null}
        {quoteResult}
      </div>
    );
  }
}

export default Products;
