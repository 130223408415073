import { connect } from 'react-redux';
import { select } from '@rematch/select';
import TableUsers from  '../components/TableUsers';

const mapStateToProps = state => {
  return {
    users: select.user.filteredUsers(state),
    loggedUser: state.auth.user,
  };
};

const mapDispatchToProps = ({ user, priceList }) => {
  return {
    fetchLists() {
      priceList.fetchLists();
    },
    fetchUsers() {
      user.fetchUsers();
    },
    setEditableUser(data) {
      user.setEditableUser(data);
    },
    removeUser(userId) {
      user.removeUserEffect(userId);
    },
    setSearch({ target: { value } }) {
      user.setSearch(value);
    },
  };
};

const tableUsersContainer = connect(mapStateToProps, mapDispatchToProps)(TableUsers);

export default tableUsersContainer;
