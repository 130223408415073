import React, { Component } from 'react';
import HorizontalField from '../../../../components/HorizontalField';

class ChangeUserPasswordModal extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { change: '' };
    this.onChange = this.onChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  onButtonClick() {
    this.props.changePassword(this.state.change);
  }

  render() {
    const { closeModal } = this.props;
    const { change } = this.state;
    console.log(change);
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-content">
          <div className="box">
            <h1 className="is-title">Cambio de Contrase&ntilde;a</h1>
            <HorizontalField
              value={change}
              name="change"
              label="Nueva Password"
              onChange={this.onChange}
              type="password"
            />
            <div className="buttons is-centered">
              <button className={`button is-success`} onClick={this.onButtonClick} disabled={!change}>Cambiar</button>
              <button className="button" onClick={closeModal}>Cerrar</button>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeModal} />
      </div>
    );
  }
}

export default ChangeUserPasswordModal;
