import React from 'react';

const EditableField = ({ editable = false, disabled = false, value, name, onChange, refer, prefix = '' }) => {
  return !editable ? (
    <span>{prefix}{value}</span>
  ) : (
    <input type="text" value={value} name={name} disabled={disabled} onChange={onChange} ref={refer} />
  );
};

export default EditableField;
