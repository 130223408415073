import update from 'immutability-helper';

export const state = {
  points: {},
  editablePoint: null,
  search: '',
  loading: false,
  cpError: false,
  cpLoading: false,
};

export const reducers = {
  updatePoints(state, data) {
    const points = data.reduce((acum, point) => ({
      ...acum, 
      [point.id]: point,
    }), {});
    return update(state, {
      points: { $merge: points },
    });
  },
  removePoint(state, pointId) {
    return update(state, {
      points: {
        $apply: function(obj) {
          const copypoints = {...obj};
          delete copypoints[pointId];
          return copypoints;
        },
      },
    });
  },
  setEditablePoint(state, point) {
    return update(state, {
      editablePoint: { $set: point },
    });
  },
  setSearch(state, value) {
    return update(state, { search: { $set: value } });
  },
  setLoading(state, value) {
    return update(state, { loading: { $set: value } });
  },
  setCpError(state, value) {
    return update(state, { cpError: { $set: value } });
  },
  setCpLoading(state, value) {
    return update(state, { cpLoading: { $set: value } });
  },
};
