import api from '../../services/api';

const effects = {
  async fetchLists() {
    try {
      const data = await api.priceList.getAll();
      this.updateLists(data);
    } catch (error) {
      console.log('Error in fetchLists', error);
      // this.failFetchlists(error);
    }
  },
  async saveList(list) {
    try {
      const { id, ...content } = list;
      const data = !id
        ? await api.priceList.post(content)
        : await api.priceList.put(id, content);
      this.updateLists([data]);
      this.setEditableList(null);
    } catch (error) {
      console.log('Error in saveList', error);
      // this.failFetchlists(error);
    }
  },
  async removeListEffect(listId) {
    try {
      if (listId) {
        await api.priceList.delete(listId);
        this.removeList(listId);
      }
    } catch (error) {
      console.log('Error in removeListEffect', error);
      // this.failFetchlists(error);
    }
  },
};

export default effects;
