/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';

class Tag extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.onTagClick = this.onTagClick.bind(this);
  }

  onTagClick() {
    this.props.onClick(this.props.value);
  }

  render() {
    const { content, number } = this.props;
    const numberTag = !number ? null : (
      <span className="tag" style={{ marginTop: '8px' }}>{number}</span>
    );
    return (
      <div className="control">
        <div className="tags has-addons">
          <span className="tag is-medium">
            {content} {numberTag}
          </span>
          <a onClick={this.onTagClick} className="tag is-medium is-delete" />
        </div>
      </div>
    );
  }
}

export default Tag;
