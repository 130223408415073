import React from 'react';
import moment from "moment/moment";
import EditableField from '../../../../../../components/EditableField';
import ErrorMessage from '../../../../../../components/ErrorMessage';
import PointMiniCard from '../../../../../../components/PointMiniCard';

const AddressesView = ({
  delivery: originalDelivery,
  point,
  type = 'origen',
  editable,
  loading,
  onChange,
  cpError,
  cpLoading,
}) => {
  const delivery = editable || originalDelivery;
  const generateOrigen = () => (
    <React.Fragment>
      <h1 className="subtitle is-3 has-text-centered">Origen</h1>
      <ul>
        <li>
          <span className="has-text-weight-bold">Nombre: </span>
          <EditableField
            value={delivery.nombre_origen}
            name="nombre_origen"
            editable={editable}
            disabled={loading}
            onChange={onChange}
          />
        </li>
        <li>
          <span className="has-text-weight-bold">Direcci&oacute;n: </span>
          <EditableField
            value={delivery.direccion_origen}
            name="direccion_origen"
            editable={editable}
            disabled={loading}
            onChange={onChange}
          />
        </li>
        <li>
          <span className="has-text-weight-bold">C&oacute;digo Postal: </span>
          <EditableField
            value={delivery.cp_origen}
            name="cp_origen"
            editable={editable}
            disabled={loading}
            onChange={onChange}
          />
          <ErrorMessage className="w-100" color="is-info" message="Validando codigo postal..." show={cpLoading && delivery.cp_origen !== ''} />
          <ErrorMessage className="w-100" message={cpError.cp_origen} show={!!cpError.cp_origen} />
        </li>
      </ul>
      <PointMiniCard point={point} />
    </React.Fragment>
  );
  const generateDestino = () => {
    const itemEdificio = editable
      ? (
        <li>
          <label className="checkbox">
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              name="es_edificio"
              checked={delivery.es_edificio}
              onChange={onChange}
            />
            <span>Es Edificio | Pisos </span>
            <input
              type="text"
              style={{ marginRight: '5px' }}
              name="pisos"
              value={delivery.pisos}
              disabled={!delivery.es_edificio}
              onChange={onChange}
            />
          </label>
        </li>
      ) : !delivery.es_edificio
      ? null
      : (
        <li>Edificio {delivery.pisos} Pisos</li>
      );
    const itemCobroCargoTransporte = editable ? (
      <li>
        <label className="checkbox">
          <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            name="cargo_transporte"
            checked={delivery.cargo_transporte}
            onChange={onChange}
          />
          <span>Cobro de viaje a cargo de transporte</span>
        </label>
      </li>
    ) : !delivery.cargo_transporte ? null : (
      <li>Cobro de viaje a cargo de transporte</li>
    );
    const itemInmendiata = editable ? (
      <React.Fragment>
        <li>
          <label className="checkbox">
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              name="mercaderia_inmediata"
              checked={delivery.mercaderia_inmediata}
              onChange={onChange}
            />
            <span> Disponibilidad Inmediata</span>
          </label>
        </li>
        <li>
          <span>Fecha Disponibilidad: </span>
          <input
            type="text"
            style={{ marginRight: '5px' }}
            name="fecha_disponible"
            placeholder="Formato AAAA-MM-DD"
            value={delivery.fecha_disponible}
            disabled={delivery.mercaderia_inmediata}
            onChange={onChange}
          />
        </li>
      </React.Fragment>
    ) : delivery.mercaderia_inmediata ? (
      <li>Mercader&iacute;a con disponibilidad inmediata</li>
    ) : (
      <li>Mercader&iacute;a disponible el {moment(delivery.fecha_disponible).format('DD/MM/YYYY')}</li>
    );
    return (
      <React.Fragment>
        <h1 className="subtitle is-3 has-text-centered">Destino</h1>
        <ul>
          <li>
            <span className="has-text-weight-bold">Nombre: </span>
            <EditableField
              value={delivery.nombre_destino}
              name="nombre_destino"
              editable={editable}
              disabled={loading}
              onChange={onChange}
            />
          </li>
          <li>
            <span className="has-text-weight-bold">Direcci&oacute;n: </span>
            <EditableField
              value={delivery.direccion_destino}
              name="direccion_destino"
              editable={editable}
              disabled={loading}
              onChange={onChange}
            />
          </li>
          <li>
            <span className="has-text-weight-bold">C&oacute;digo Postal: </span>
            <EditableField
              value={delivery.cp_destino}
              name="cp_destino"
              editable={editable}
              disabled={loading}
              onChange={onChange}
            />
            <ErrorMessage className="w-100" color="is-info" message="Validando codigo postal..." show={cpLoading && delivery.cp_destino !== ''} />
            <ErrorMessage className="w-100" message={cpError.cp_destino} show={!!cpError.cp_destino} />
          </li>
          {itemEdificio}
          {itemCobroCargoTransporte}
          {itemInmendiata}
        </ul>
        <PointMiniCard point={point} />
      </React.Fragment>
    );
  };
  return type === 'origen' ? generateOrigen() : generateDestino();
};

export default AddressesView;
