import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class postalCode {
  static async validate(key,code) {
    const response = await axios.get(`${apiUrl}/codigosPostales/codigo/${code}/${key}`, authHeader());
    return response.data;
  }
  static async validates(key,code) {
    const response = await axios.get(`${apiUrl}/codigosPostales/codigos/${code}/${key}`, authHeader());
    return response.data;
  }
}

export default postalCode;
