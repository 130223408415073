import React from 'react';
import { dispatch } from '@rematch/core';
import TableUsers from './containers/tableUsersContainer';
import Icon from '../../components/Icon';

const newUserTemplate = {
  usuario: '',
  email: '',
  password: '',
  rol: '0',
  razon_social: '',
  nombre_fantasia: '',
  telefono: '',
  cuit: '',
  condicion_iva: '',
  domicilio: '',
  codigo_postal: '',
  provincia: '',
  rubro: '',
  condicion_pago: '',
  lista_precios: 1,
  sitio_web: '',
  redes: '',
  venta_internet: false,
};

const Users = () => {
  return (
    <div className="column route-content">
      <div className="content">
        <section className="section">
          <h1 className="title is-1 has-text-centered">ABM Usuarios</h1>
          <button
            className="button is-primary is-large"
            onClick={() => dispatch.user.setEditableUser({ ...newUserTemplate })}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              width: '55px',
              borderRadius: '100%',
              zIndex: '100000',
            }}
          >
            <Icon icon="fas fa-plus" />
          </button>
          <TableUsers/>
        </section>
      </div>
    </div>
  );
};

export default Users;
