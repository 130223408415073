import React, { Component } from 'react';
import FilterItem from './components/FilterItem';
import Tag from './components/Tag';

class FilterModal extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { selectedFilter: null };
    this.selectFilter = this.selectFilter.bind(this);
    this.selectValue = this.selectValue.bind(this);
    this.clearByFilterType = this.clearByFilterType.bind(this);
    this.clearSelectedFilter = this.clearSelectedFilter.bind(this);
  }

  selectFilter(value) {
    this.setState({ selectedFilter: value });
  }

  selectValue(value) {
    const { filtersSelected, onSelect, onDeselect } = this.props;
    const { selectedFilter: filter } = this.state;
    if (filtersSelected && filtersSelected[filter] && filtersSelected[filter].includes(value)){
      onDeselect({ filter, value });
    } else {
      onSelect({ filter, value });
    }
  }

  clearByFilterType(filter) {
    const { filtersSelected } = this.props;
    filtersSelected[filter].forEach(value => this.props.onDeselect({ filter, value }));
  }

  clearSelectedFilter() {
    this.setState({ selectedFilter: null });
  }

  render() {
    const { filters, filtersSelected, onClearAll, onClose, filtersLabel } = this.props;
    const { selectedFilter } = this.state;

    const items = selectedFilter
      ? Object.entries(filters[selectedFilter]).map(([label, value]) => {
        const isSelected = filtersSelected
          && filtersSelected[selectedFilter]
          && filtersSelected[selectedFilter].includes(value);
        return (
          <FilterItem
            key={value}
            label={label}
            value={value}
            onClick={this.selectValue}
            isActive={isSelected}
          />
        );
      })
      : filters
        ? Object.keys(filters).map(filter => {
          const hasSelection = filtersSelected
            && filtersSelected[filter]
            && filtersSelected[filter].length;
          const label = filtersLabel && filtersLabel[filter] ? filtersLabel[filter] : filter;
          return (
            <FilterItem
              key={filter}
              label={label}
              value={filter}
              onClick={this.selectFilter}
              isActive={hasSelection}
            />
          );
        })
        : null;
    const heading = !selectedFilter
      ? (
        <p className="panel-heading is-marginless">Filtrar por</p>
      )
      : (
        <p onClick={this.clearSelectedFilter} className="panel-heading is-marginless" style={{ cursor: 'pointer' }}>
          <span className="icon is-size-6 is-pulled-right" style={{ paddingTop: '3px' }}><i className="fas fa-arrow-left" /></span> {filtersLabel && filtersLabel[selectedFilter] ? filtersLabel[selectedFilter] : selectedFilter}
        </p>
      );
    const tags = !filtersSelected ? null
      : !selectedFilter && filters && filtersSelected
        ? Object.entries(filtersSelected).map(([filter, values]) => !values.length ? null : (
          <Tag
            key={filter}
            content={filtersLabel && filtersLabel[filter] ? filtersLabel[filter] : filter}
            number={values.length}
            value={filter}
            onClick={this.clearByFilterType}
          />
        ))
        : filtersSelected && filtersSelected[selectedFilter]
          ? filtersSelected[selectedFilter].map(value => {
            const label = Object.entries(filters[selectedFilter])
              .find(([label, val]) => val === value)[0];
            return (
              <Tag
                key={value}
                content={label}
                value={value}
                onClick={this.selectValue}
              />
            );
          })
          : null;
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={onClose} />
        <div className="modal-content">
          <div className="box">
            <h1 className="is-title">Filtros</h1>
            <div className="field is-grouped" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
              {tags}
            </div>
            <nav className="panel">
              {heading}
              {items}
              <div className="panel-block">
                <button onClick={onClearAll} className="button is-link is-outlined is-fullwidth">
                  Limpiar todos los filtros
                </button>
              </div>
            </nav>
            <div className="buttons is-right">
              <button className="button" onClick={onClose}>Cerrar</button>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" onClick={onClose} />
      </div>
    );
  }
}

export default FilterModal;