import React from 'react';
import ContactFields from './components/ContactFields';

const Contact = ({ quote, onInputChange, errors, overrideContact }) => {
  const inputHandler = ({ target: { name: key, value } }) => onInputChange({ key, value });
  const {
    contacto,
    contacto_mail,
    contacto_whatsapp,
    contacto_telefono,
  } = quote;
  const contact1 = {
    nombre: { key: 'contacto', value: contacto },
    email: { key: 'contacto_mail', value: contacto_mail },
    telefono: { key: 'contacto_telefono', value: contacto_telefono },
    whatsapp: { key: 'contacto_whatsapp', value: contacto_whatsapp },
  };

  const errorMessage = !errors.contactos ? null : (
    <article className="message is-danger">
      <div className="message-body">
        {errors.contactos}
      </div>
    </article>
  );

  return (
    <div className="content">
      <h1 className="title is-centered">Contacto Principal</h1>
      <div className="box is-marginless">
        <ContactFields errors={errors} contact={contact1} onChange={inputHandler} override={overrideContact} />
        {errorMessage}
      </div>
    </div>
  );
};

export default Contact;
