import React, { Component } from 'react';
import UserItem from '../UserItem';
import UserForm from '../../containers/userFormContainer';
import ConfirmModal from '../../../../components/ConfirmModal';
import InformationModal from '../../../../components/InformationModal';
import SearchInput from '../../../../components/SearchInput';
import ChangeUserPasswordModal from '../ChangeUserPasswordModal';
import apiService from '../../../../services/api';

class TableUsers extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { userToBeDeleted: null, changeModal: false, userId: null, modalToken: false, token: null };
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.setUserToBeDeleted = this.setUserToBeDeleted.bind(this);
    this.confirmUserDelete = this.confirmUserDelete.bind(this);
    this.openChangeModal = this.openChangeModal.bind(this);
    this.closeChangeModal = this.closeChangeModal.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    this.props.fetchLists();
    this.props.fetchUsers();
  }

  closeDeleteModal() {
    this.setState({ userToBeDeleted: null });
  }

  setUserToBeDeleted(userId) {
    this.setState({ userToBeDeleted: userId });
  }

  confirmUserDelete(userId) {
    this.props.removeUser(userId);
    this.setState({ userToBeDeleted: null });
  }

  openChangeModal(userId) {
    this.setState({ changeModal: true, userId });
  }

  closeChangeModal() {
    this.setState({ changeModal: false, userId: null });
  }

  changePassword(password) {
    const { userId } = this.state;
    apiService.user.put(userId, { password });
    this.closeChangeModal();
  }

  generateToken = async (userId) => {
    const token = await apiService.auth.getToken(userId)
    this.setState({ token: token })
    this.setState({ modalToken: true });
  }

  render() {
    const { userToBeDeleted, changeModal } = this.state;
    const { users, setSearch, loggedUser } = this.props;
    const { setEditableUser } = this.props;
    const rows = users.map(user =>
      <UserItem
        user={user}
        key={user.id}
        loggedUser={loggedUser}
        onEditClick={setEditableUser}
        onDeleteClick={this.setUserToBeDeleted}
        openChangeModal={this.openChangeModal}
        generateToken={this.generateToken}
      />
    );
    const modal = !userToBeDeleted ? null : (
      <ConfirmModal
        title="Confirmaci&oacute;n"
        message="Esta seguro de eliminar este usuario?"
        onCancel={this.closeDeleteModal}
        onConfirm={() => this.confirmUserDelete(userToBeDeleted)}
      />
    );
    const infModal = (
      <InformationModal
        title="Token de usuario"
        message={this.state.token}
        onCancel={() => this.setState({ modalToken: false })}
      />)

    const changePassModal = !changeModal ? null : (
      <ChangeUserPasswordModal closeModal={this.closeChangeModal} changePassword={this.changePassword} />
    );

    return (
      <React.Fragment>
        {modal}
        {changePassModal}
        {this.state.modalToken && infModal}
        <UserForm />
        <SearchInput onChange={setSearch} />
        <div className="box is-marginless">
          <table className="table is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Raz&oacute;n Social</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default TableUsers;
