import React, { Component } from 'react';
import HorizontalField from '../../../../../../components/HorizontalField';
import ProductDimensions from './components/ProductDimensions';
import SelectProduct  from '../../../../../../components/SelectProduct';

class ProductModal extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { product: { ...this.props.product }, hasError: false };
    this.onDimensionChange = this.onDimensionChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveProduct = this.saveProduct.bind(this);

  }

  onChange({ target: { name, value } }) {

    if ((name === 'cantidad'|| name === 'peso' || name === 'valor_declarado' )
        && value < 0 && value !== '') {
      value = 0;
    }

    this.setState({
      product: { ...this.state.product, [name]: value },
    });

    if (name === 'selectProducto') {
      var alto ='';
      var ancho = '';
      var profundo = '';
      var peso = '';
      var valor_declarado = '';
      var producto = '';

      if (value !== '') {
        let fields = value.split('-');
         alto = fields[0];
         ancho = fields[1];
         profundo = fields[2];
         peso = fields[3];
         valor_declarado = fields[4];
         producto = fields[5];
      }


        this.setState({
          product: { ...this.state.product, producto,alto,ancho,profundo,peso,valor_declarado },
        });

    }

  }

  onDimensionChange({ alto, ancho, profundo }) {

    alto = alto < 0 ? 0 : alto;
    ancho = ancho < 0 ? 0 : ancho;
    profundo = profundo < 0 ? 0 : profundo;

    this.setState({
      product: { ...this.state.product, alto, ancho, profundo },
    });

  }

  saveProduct() {
    const { product } = this.state;
    const { observaciones, ...restFields } = product;
    const valid = Object.values(restFields).findIndex(field => !field || parseInt(field, 10) === 0) === -1;
    if (!valid) {
      this.setState({ hasError: true });
    } else {
      this.props.onSave(product);
      this.closeModal();
    }
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    const { product, hasError } = this.state;
    const errorMessage = !hasError ? null : (
      <article className="message is-danger">
        <div className="message-body">
          Todos los campos son requeridos.
        </div>
      </article>
    );
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title is-marginless">Producto</p>
            <button className="delete" aria-label="close" onClick={this.closeModal} />
          </header>
          <section className="modal-card-body">
            <div>
              <SelectProduct   onChange={this.onChange} />
            </div>
            <HorizontalField
              label="Producto"
              placeholder="Producto..."
              name="producto"
              value={product.producto}
              onChange={this.onChange}
            />
          <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Dimensiones</label>
              </div>
              <div className="field-body" style={{ display: 'block'}}>
                <ProductDimensions {...product} onChange={this.onDimensionChange} />
              </div>
            </div>
            <HorizontalField
              label="Peso (kg)"
              placeholder="Peso..."
              type="number"
              name="peso"
              value={product.peso}
              onChange={this.onChange}
            />
            <HorizontalField
              label="Valor declarado"
              placeholder="Valor declarado..."
              type="number"
              name="valor_declarado"
              value={product.valor_declarado}
              onChange={this.onChange}
            />
            <HorizontalField
              label="Cantidad"
              placeholder="Cantidad..."
              type="number"
              name="cantidad"
              value={product.cantidad}
              onChange={this.onChange}
            />
            <HorizontalField
              label="Comentarios"
              placeholder="Comentarios y Observaciones..."
              type="text"
              name="observaciones"
              value={product.observaciones}
              onChange={this.onChange}
            />
            {errorMessage}
          </section>
          <footer className="modal-card-foot">
            <div style={{ textAlign: 'center', width: '100%' }}>
              <button className="button is-success" onClick={this.saveProduct}>Guardar</button>
              <button className="button" onClick={this.closeModal}>Cancelar</button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ProductModal;
