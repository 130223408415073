import React, { Component } from 'react';
import FrequentPointsModal from '../FrequentPointsModal';
import ErrorMessage from '../../../../../../components/ErrorMessage';
import PointMiniCard from '../../../../../../components/PointMiniCard';
import './addressFields.css';
import AutosuggestPostalCode from '../../../../../../components/AutosuggestPostalCode';

class AddressFields extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { modal: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onPointSelect = this.onPointSelect.bind(this);
    this.removePoint = this.removePoint.bind(this);
  }

  openModal() {
    this.setState({ modal: true });
  }

  closeModal() {
    this.setState({ modal: false });
  }

  onPointSelect(point) {
    const {
      fields: { nombre, direccion, codigo_postal, guardar, id_punto_frecuente },
      onChanges,
    } = this.props;
    onChanges([
      { key: nombre.key, value: point.nombre },
      { key: direccion.key, value: point.direccion_origen },
      { key: codigo_postal.key, value: point.codigo_postal },
      { key: guardar.key, value: false },
      { key: id_punto_frecuente.key, value: point.id },
    ]);
    this.closeModal();

  }

  removePoint() {
    const {
      fields: { nombre, direccion, codigo_postal, guardar, id_punto_frecuente },
      onChanges,
    } = this.props;

    onChanges([
      { key: nombre.key, value: '' },
      { key: direccion.key, value: '' },
      { key: codigo_postal.key, value: '' },
      { key: guardar.key, value: false },
      { key: id_punto_frecuente.key, value: null },
    ]);
  }

  render() {

    const {
      fields: { codigo_postal, id_punto_frecuente },
      point,
      loading,
      onChange,
      errors,
    } = this.props;
    const modal = !this.state.modal ? null : (
      <FrequentPointsModal onPointSelect={this.onPointSelect} closeModal={this.closeModal} />
    );
    const frequentPointButton = !id_punto_frecuente.value ? (
      <button className="button is-info" onClick={this.openModal}>Puntos Frecuentes</button>
    ) : (
      <button className="button is-danger" onClick={this.removePoint}>Quitar</button>
    );
    const pointMiniCard = !point ? null : <PointMiniCard point={point} />;
    return (
      <React.Fragment>
        {modal}
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">C&oacute;digo Postal o Localidad </label>
          </div>
          <div className="field-body">
            <div className="field has-addons flex-wrap">
              <div className="control is-expanded">
                <AutosuggestPostalCode
                  className={`input ${!errors[codigo_postal.key] ? '' : 'is-danger'}`}
                  placeholder="Codigo postal..."
                  onChange={onChange}
                  name={codigo_postal.key}
                  value={codigo_postal.value}
                  disabled={id_punto_frecuente.value}
                  inputval={codigo_postal.value}
                />
              </div>
              <div className="control">
                {frequentPointButton}
              </div>
              <ErrorMessage className="w-100" color="is-info" message="Validando codigo postal..." show={loading && codigo_postal.value !== ''} />
              <ErrorMessage className="w-100" message={errors[codigo_postal.key]} show={!!errors[codigo_postal.key] && !loading} />
            </div>
          </div>
        </div>
        <ul>
          {pointMiniCard}
        </ul>
      </React.Fragment>
    );
  }
}

export default AddressFields;
