import React from 'react';

const InformationModal = ({ title, message, onCancel }) => (
  <div className="modal is-active">
    <div className="modal-background" onClick={onCancel}></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        <button className="delete" aria-label="close" onClick={onCancel}></button>
      </header>
      <section className="modal-card-body">
        {message}
      </section>
      <footer className="modal-card-foot">
      </footer>
    </div>
    <button className="modal-close is-large" aria-label="close" onClick={onCancel}></button>
  </div>
);

export default InformationModal;
