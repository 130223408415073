import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { dispatch } from '@rematch/core';
import TableDeliveries from './containers/tableDeliveriesContainer';
import DeliveryView from './containers/deliveryViewContainer';

class Deliveries extends Component {
  constructor(props) {
    super();
    this.props = props;
  }

  componentDidMount() {
    dispatch.delivery.fetchDeliveries();
  }

  render() {
    const { match } = this.props;
    return (
      <div className="column route-content">
        <div className="content">
          <Route path={`${match.url}/:id`} component={DeliveryView} />
          <Route exact path={match.url} component={TableDeliveries} />
        </div>
      </div>
    );
  }
}

export default Deliveries;
