import { createSelector } from "reselect";
import { filter } from "../../utils/helpers";

export const deliveries = (state) => state.deliveries;
export const deliveryById = (state, { deliveryId }) =>
  state.deliveries[deliveryId];
export const filtersSelected = (state) => state.filtersSelected;
export const search = (state) => state.search;
export const loading = (state) => state.loading;
export const cpError = (state) => state.cpError;
export const cpLoading = (state) => state.cpLoading;
export const startDate = (state) => state.startDate;
export const endDate = (state) => state.endDate;
export const statusProps = (state, status) => status;

export const deliveriesArray = createSelector(deliveries, (deliveries) =>
  Object.values(deliveries)
);

export const populatedDeliveries = createSelector(
  deliveriesArray,
  statusProps,
  (deliveries, status) => {
    return deliveries.map((delivery) => {
      const estado = !status[delivery.estado_id]
        ? ""
        : status[delivery.estado_id].estado;
      const volumen = !delivery.envios_detalles
        ? 0
        : delivery.envios_detalles.reduce((acum, { ancho, alto, profundo }) => {
            const vol =
              (parseFloat(ancho) / 100) *
              (parseFloat(alto) / 100) *
              (parseFloat(profundo) / 100);
            return acum + vol;
          }, 0);
      const peso = !delivery.envios_detalles
        ? 0
        : delivery.envios_detalles.reduce((acum, { peso, cantidad }) => {
            return acum + peso * cantidad;
          }, 0);
      return {
        ...delivery,
        estado,
        volumen,
        peso,
      };
    });
  }
);

export const filteredSelectedDeliveries = createSelector(
  populatedDeliveries,
  filtersSelected,
  (deliveries, filters) =>
    deliveries.filter((delivery) => {
      const { usuario, facturacion, estado_pago, cargo_transporte, ...rest } =
        filters;
      if (
        usuario &&
        usuario.length &&
        !usuario.includes(delivery.user.usuario)
      ) {
        return false;
      }
      if (
        facturacion &&
        facturacion.length &&
        !facturacion.includes(!!delivery.facturacion)
      ) {
        return false;
      }
      if (
        estado_pago &&
        estado_pago.length &&
        !estado_pago.includes(delivery.estado_pago)
      ) {
        return false;
      }
      if (
        cargo_transporte &&
        cargo_transporte.length &&
        !cargo_transporte.includes(!!delivery.cargo_transporte)
      ) {
        return false;
      }
      const entries = Object.entries(rest);
      if (!entries.length || !entries.find(([key, values]) => values.length)) {
        return true;
      }
      return !!entries.find((entry) => {
        const [key, values] = entry;

        return values.includes(delivery[key]);
      });
    })
);

export const filteredDeliveries = createSelector(
  filteredSelectedDeliveries,
  search,
  (deliveries, search) =>
    deliveries.filter(filter(search, ["id", "usuario_id"]))
);

export const summaries = createSelector(
  deliveriesArray,
  statusProps,
  (deliveries, status) => {
    return Object.values(status).map((statu) => ({
      ...statu,
      number: deliveries.filter((delivery) => delivery.estado_id === statu.id)
        .length,
    }));
  }
);

export const qtyPaymentPending = createSelector(
  deliveriesArray,
  (deliveries) =>
    deliveries.filter((delivery) => delivery.estado_pago === "Pendiente").length
);

export const filters = createSelector(
  statusProps,
  populatedDeliveries,
  (status, deliveries) => {
    const statusFilter = Object.values(status).reduce(
      (acum, statu) => ({ ...acum, [statu.estado]: statu.id }),
      {}
    );
    const usersFilter = deliveries.reduce(
      (acum, delivery) => ({
        ...acum,
        [delivery.user.usuario]: delivery.user.usuario,
      }),
      {}
    );

    return {
      filters: {
        estado_id: statusFilter,
        facturacion: { Si: true, No: false },
        usuario: usersFilter,
        estado_pago: { Pendiente: "Pendiente", Pagado: "Pagado" },
        cargo_transporte: { Si: true, No: false },
      },
      labels: {
        estado_id: "Estado",
        facturacion: "Facturado",
        usuario: "Cliente",
        estado_pago: "Estado del Pago",
        cargo_transporte: "Cobro Cargo Transporte",
      },
    };
  }
);
