import React from 'react';

const ProductMiniCard = ({product}) => (
  <li>
    <div>{product.producto} (Cant. {product.cantidad})</div>
    <div className="subtitle is-size-7">
      Vol(cm): {product.ancho}x{product.alto}x{product.profundo}, Peso(kg): {product.peso}, Valor declarado: ${product.valor_declarado}{product.observaciones !== ""? `, observaciones: ${product.observaciones}` : ''}
    </div>
  </li>
);

export default ProductMiniCard;
