import React from 'react';
import Icon from '../Icon';

const SearchInput = ({ value, onChange, size = 'is-medium' }) => {
  return (
    <div className="field">
      <div className="control has-icons-left">
        <input
          className={`input ${size}`}
          type="text"
          placeholder="Buscar..."
          value={value}
          onChange={onChange}
        />
        <Icon icon="fas fa-search" className="is-left" />
      </div>
    </div>
  );
};

export default SearchInput;
