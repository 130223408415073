import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class UserApi {
  static async getAll() {
    // TODO: falta
    const response = await axios.get(`${apiUrl}/auth/users`, authHeader());
    return response.data.data;
  }
  static async put(userId, user) {
    // TODO: falta
    const response = await axios.put(`${apiUrl}/auth/user/${userId}`, user, authHeader());
    return response.data;
  }
  static async post(user) {
    const response = await axios.post(`${apiUrl}/auth/register`, user, authHeader());
    return response.data;
  }
  static async delete(userId) {
    // TODO: falta
    const response = await axios.delete(`${apiUrl}/auth/user/${userId}`, authHeader());
    return response.data;
  }
  static async loggedUser() {
    const response = await axios.get(`${apiUrl}/auth/user`, authHeader());
    return response.data;
  }
}

export default UserApi;
