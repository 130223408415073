import React, { Component } from 'react';

class Completed extends Component {
  constructor(props) {
    super();
    this.props = props;
  }

  componentDidMount() {
    this.props.saveQuote();
  }

  render() {
    const message = !this.props.saved
      ? 'Guardando entrega...'
      : 'La entrega ha sido creada, pronto un representante se comunicara para confirmar.';
    return (
      <div className="content">
        <h1 className="title is-centered">Entrega creada</h1>
        <article className="message is-info">
          <div className="message-body has-text-centered">
            {message}
          </div>
        </article>
      </div>
    );
  }
}

export default Completed;
