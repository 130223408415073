import api from '../../services/api';

const effects = {
  async quote({ quote, save = false }) {
    try {
      const data = await api.delivery.quote(quote, save);
      this.setAmount(data.cotizacion);
      if (save) {
        this.setSaved(true);
        this.setAmount(null);
        this.newQuote();
      }
      this.setMakeQuote(false);
    } catch (error) {
      console.log('Error in fetchDeliveries', error);
    }
  },
  async validateCode({ key, code }, rootState) {
    try {
      this.setLoading(true);
      const data = await api.postalCode.validate(key,code);
      const { cpError, errors } = rootState.quote;
      if (!data || !data.length) {
        this.setCpError({ ...cpError, [key]: 'Codigo Postal no encontrado' });
      } else {
        const newCp = { ...cpError };
        const newErrors = errors.filter(error => error.key !== key);
        delete newCp[key];
        this.setCpError(newCp);
        this.setErrors(newErrors);
      }
      this.setLoading(false);
    } catch (error) {
      this.setLoading(false);
      console.log('Error in fetchDeliveries', error);
    }
  },
};

export default effects;
