import { connect } from 'react-redux';
import { select } from '@rematch/select';
import DeliveryView from '../components/DeliveryView';

const mapStateToProps = (state, { match: { params: { id: deliveryId } } }) => {
  const delivery = select.delivery.deliveryById(state, { deliveryId });
  const statuses = select.status.status(state);
  const status = !delivery ? null : select.status.statusById(state, { statusId: delivery.estado_id});
  const role = state.auth.rol;
  const loading = select.delivery.loading(state);
  const cpError = select.delivery.cpError(state);
  const cpLoading = select.delivery.cpLoading(state);
  const map = {
    delivery,
    status,
    statuses,
    role,
    loading,
    cpError,
    cpLoading,
  };
  if (delivery) {
    const { id_punto_frecuente_origen, id_punto_frecuente_destino } = delivery;
    map.originPoint = !id_punto_frecuente_origen
      ? null
      : select.frequentPoint.pointById(state, { pointId: id_punto_frecuente_origen });
    map.destinyPoint = !id_punto_frecuente_destino
      ? null
      : select.frequentPoint.pointById(state, { pointId: id_punto_frecuente_destino });
  }
  return map;
};

const mapDispatchToProps = ({ delivery }) => {
  return {
    saveDelivery: deliver => {
      delivery.saveDelivery(deliver);
    },
    validateCode: ({ key, code }) => {
      delivery.validateCode({ key, code });
    },
    setCpLoading: (value) => {
      delivery.setCpLoading(value);
    },  removeDelivery(deliver) {
        delivery.removeDeliveryEffect(deliver);
      },
  };
};

const deliveryViewContainer = connect(mapStateToProps, mapDispatchToProps)(DeliveryView);

export default deliveryViewContainer;
