import React from 'react';

const ProductItem = ({ product, url, history, rol }) => {
  const onClick = () => history.push(`${url}/${product.id}`);
  const clienteCol = !rol ? null : <td>{product.user.usuario}</td>;
  return (
    <tr onClick={onClick} key={product.id} style={{ cursor: 'pointer' }}>
      <td>{product.producto}</td>
      <td>{product.alto}</td>
      <td>{product.ancho}</td>
      <td>{product.profundo}</td>
      <td>{product.peso}</td>
      <td>{product.valor_declarado}</td>
      {clienteCol}
    </tr>
  );
};

export default ProductItem;
