import React, { Component } from 'react';

class FilterItem extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.onItemClick = this.onItemClick.bind(this);
  }

  onItemClick() {
    this.props.onClick(this.props.value);
  }

  render() {
    const { label, isActive } = this.props;
    return (
      <a
        onClick={this.onItemClick}
        className={`panel-block ${!isActive ? '' : 'is-active'}`}
      >
        {label}
      </a>
    );
  }
}

export default FilterItem;
