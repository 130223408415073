import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import selectorsPlugin from '@rematch/select';
import { init } from '@rematch/core';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import 'bulma/css/bulma.css';
import 'bulma-steps/dist/bulma-steps.min.css';
import './bulma-calendar.min.css';
import './index.css';
import models from './modules';

const select = selectorsPlugin();
const store = init({ models, plugins: [select] });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('root')
);
registerServiceWorker();
