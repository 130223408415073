import { createSelector } from "reselect";
import { filter } from "../../utils/helpers";

export const status = state => state.status;
export const statusById = (state, { statusId }) => state.status[statusId];
export const search = state => state.search;

export const filteredStatus = createSelector(
  status,
  search,
  (status, search) => Object.values(status).filter(filter(search, ['id', 'usuario_id'])),
);
