import React from 'react';
import EditableField from '../../../../../../components/EditableField';

const ContactView = ({ delivery: originalDelivery, title, editable, loading, onChange }) => {
  const delivery = editable || originalDelivery;
  const emailLi = !delivery.contacto_mail ? null : (
    <li>
      <span className="has-text-weight-bold">Email: </span>
      <EditableField
        value={delivery.contacto_mail}
        name={"contacto_mail"}
        editable={editable}
        disabled={loading}
        onChange={onChange}
      />
    </li>
  );
  const telefonoLi = !delivery.contacto_telefono ? null : (
    <li>
      <span className="has-text-weight-bold">Telefono: </span>
      <EditableField
        value={delivery.contacto_telefono}
        name={"contacto_telefono"}
        editable={editable}
        disabled={loading}
        onChange={onChange}
      />
    </li>
  );
  const whatsappLi = !delivery.contacto_whatsapp ? null : (
    <li>
      <span className="has-text-weight-bold">Whatsapp: </span>
      <EditableField
        value={delivery.contacto_whatsapp}
        name={"contacto_whatsapp"}
        editable={editable}
        disabled={loading}
        onChange={onChange}
      />
    </li>
  );
  return (
    <React.Fragment>
      <h1 className="subtitle is-3 has-text-centered">{title}</h1>
      <ul>
        <li>
          <span className="has-text-weight-bold">Nombre: </span>
          <EditableField
            value={delivery.contacto}
            name={"contacto"}
            editable={editable}
            disabled={loading}
            onChange={onChange}
          />
        </li>
        {emailLi}
        {telefonoLi}
        {whatsappLi}
      </ul>
    </React.Fragment>
  );
};

export default ContactView;