import XLSX from "xlsx";
import moment from "moment";
import apiService from "./api";

const columnMap = {
  "Cobro a cargo de transporte": "cargo_transporte",
  "Nombre de contacto": "contacto",
  "Email de contacto": "contacto_mail",
  "Telefono de contacto": "contacto_telefono",
  "Whatsapp de contacto": "contacto_whatsapp",
  Costo: "cotizacion",
  "Destino - Codigo Postal": "cp_destino",
  "Origen - Codigo Postal": "cp_origen",
  "Fecha de creacion": "created_at",
  "Destino - Direccion": "direccion_destino",
  "Origen - Direccion": "direccion_origen",
  Estado: "estado_id",
  "Estado Confirmacion": "estado_usuario_correo",
  Facturacion: "facturacion",
  "Disponibilidad de Mercaderia": "fecha_disponible",
  "Fecha de Entrega": "fecha_entrega",
  "Fecha Envio email": "fecha_envio_mail",
  Entrega: "id",
  "Destino - Nombre": "nombre_destino",
  "Origen - Nombre": "nombre_origen",
  Observaciones: "observaciones",
  Pisos: "pisos",
  Remito: "remito",
  "Ultima Actualizacion": "updated_at",
  "Origen - Descripción localidad": "localidad_origen",
  "Destino - Descripción localidad": "localdiad_destino",
};

const columnOrder = [
  "Entrega",
  "Usuario",
  "Costo",
  "Estado",
  "Origen - Dirección",
  "Origen - Descripción localidad",
  "Destino - Dirección",
  "Destino - Descripción localidad",
  "Pisos",
  "Disponibilidad de Mercaderia",
  "Cobro a cargo de transporte",
  "Observaciones",
  "Nombre de contacto",
  "Whatsapp de contacto",
  "Producto",
];

const columnDates = [
  "Fecha de creacion",
  "Ultima Actualizacion",
  "Fecha de Entrega",
  "Fecha Envio email",
];

class Exporter {
  static async makeExport(startDate = null, endDate = null) {
    const deliveries = await apiService.delivery.getByRange(startDate, endDate);
    const statuses = await apiService.status.getAll();
    const frequentPoints = await apiService.frequentPoint.getAll();
    const estados = statuses.reduce(
      (acum, status) => ({ ...acum, [status.id]: status }),
      {}
    );
    const points = frequentPoints.reduce(
      (acum, point) => ({ ...acum, [point.id]: point }),
      {}
    );
    const rows = deliveries.map((delivery) =>
      columnOrder.map((column) => {
        if (column === "Costo") {
          return parseInt(delivery.cotizacion);
        }

        // Fechas
        if (columnDates.includes(column)) {
          const value = delivery[columnMap[column]];
          return !value ? "-" : moment(value).format("DD/MM/YYYY HH:mm");
        }
        if (column === "Estado") {
          const estado_id = delivery[columnMap[column]];
          return !estados[estado_id] ? "-" : estados[estado_id].estado;
        }
        if (column === "Pisos") {
          return !delivery.es_edificio
            ? "No es edificio"
            : delivery[columnMap[column]];
        }
        if (column === "Disponibilidad de Mercaderia") {
          return delivery.mercaderia_inmediata
            ? "Inmediata"
            : moment(delivery[columnMap[column]]).format("DD/MM/YYYY HH:mm");
        }
        if (column === "Cobro a cargo de transporte") {
          return delivery[columnMap[column]] ? "Si" : "No";
        }
        if (column === "Producto") {
          return !delivery.envios_detalles
            ? []
            : delivery.envios_detalles.reduce((acum, producto) => {
                const { ancho, alto, profundo } = producto;
                const volumen =
                  (parseFloat(ancho) / 100) *
                  (parseFloat(alto) / 100) *
                  (parseFloat(profundo) / 100);
                return [...acum, { ...producto, volumen }];
              }, []);
        }
        if (column === "Usuario") {
          return !delivery.user ? "-" : delivery.user.usuario;
        }
        // Origen
        if (column === "Origen - Dirección") {
          return delivery.direccion_origen;
        }
        if (column === "Origen - Descripción localidad") {
          return delivery.localidad_origen;
        }
        if (column === "Origen - Nombre") {
          return !delivery.id_punto_frecuente_origen
            ? delivery.nombre_origen
            : !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].nombre;
        }
        if (column === "Origen - Codigo Postal") {
          return !delivery.id_punto_frecuente_origen
            ? delivery.cp_origen
            : !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].codigo_postal;
        }
        if (column === "Origen - Direccion") {
          return !delivery.id_punto_frecuente_origen
            ? delivery.direccion_origen
            : !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].direccion_origen;
        }
        if (column === "Origen - Contacto") {
          return !delivery.id_punto_frecuente_origen ||
            !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].persona_contacto;
        }
        if (column === "Origen - Provincia") {
          return !delivery.id_punto_frecuente_origen ||
            !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].provincia;
        }
        if (column === "Origen - Telefono") {
          return !delivery.id_punto_frecuente_origen ||
            !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].telefono;
        }
        if (column === "Origen - Celular") {
          return !delivery.id_punto_frecuente_origen ||
            !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].celular;
        }
        if (column === "Origen - Horarios de Atencion") {
          return !delivery.id_punto_frecuente_origen ||
            !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].horarios_atencion;
        }
        if (column === "Origen - Notas") {
          return !delivery.id_punto_frecuente_origen ||
            !points[delivery.id_punto_frecuente_origen]
            ? "-"
            : points[delivery.id_punto_frecuente_origen].notas;
        }

        // Destino
        if (column === "Destino - Descripción localidad") {
          return delivery.localidad_destino;
        }
        if (column === "Destino - Dirección") {
          return delivery.direccion_destino;
        }
        if (column === "Destino - Nombre") {
          return !delivery.id_punto_frecuente_destino
            ? delivery.nombre_destino
            : !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].nombre;
        }
        if (column === "Destino - Codigo Postal") {
          return !delivery.id_punto_frecuente_destino
            ? delivery.cp_destino
            : !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].codigo_postal;
        }
        if (column === "Destino - Direccion") {
          return !delivery.id_punto_frecuente_destino
            ? delivery.direccion_destino
            : !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].direccion_origen;
        }
        if (column === "Destino - Contacto") {
          return !delivery.id_punto_frecuente_destino ||
            !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].persona_contacto;
        }
        if (column === "Destino - Provincia") {
          return !delivery.id_punto_frecuente_destino ||
            !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].provincia;
        }
        if (column === "Destino - Telefono") {
          return !delivery.id_punto_frecuente_destino ||
            !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].telefono;
        }
        if (column === "Destino - Celular") {
          return !delivery.id_punto_frecuente_destino ||
            !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].celular;
        }
        if (column === "Destino - Horarios de Atencion") {
          return !delivery.id_punto_frecuente_destino ||
            !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].horarios_atencion;
        }
        if (column === "Destino - Notas") {
          return !delivery.id_punto_frecuente_destino ||
            !points[delivery.id_punto_frecuente_destino]
            ? "-"
            : points[delivery.id_punto_frecuente_destino].notas;
        }
        return !delivery[columnMap[column]] ? "-" : delivery[columnMap[column]];
      })
    );
    const expandedRows = rows.reduce((acum, row) => {
      const productos = row.pop();
      if (!productos.length) {
        return [...acum, row];
      }
      const duplicated = productos.map((producto) => [
        ...row,
        producto.producto,
        producto.alto,
        producto.ancho,
        producto.profundo,
        producto.cantidad,
      ]);
      return [...acum, ...duplicated];
    }, []);
    const newColumns = [...columnOrder, "Alto", "Largo", "Ancho", "Cantidad"];
    const worksheet = XLSX.utils.aoa_to_sheet([newColumns, ...expandedRows]);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, "Entregas");
    XLSX.writeFile(newWorkbook, "Reporte de Entregas.xlsx");
  }
}

export default Exporter;
