import React, { Component } from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import exporterService from '../../services/exporter';

class Export extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { error: null, loading: false };
    this.makeExport = this.makeExport.bind(this);
  }

  componentDidMount() {
    const options = {
      lang: 'es',
      overlay: true,
    };
    new window.bulmaCalendar(this.startDate, options);
    new window.bulmaCalendar(this.endDate, options);
  }

  makeExport() {
    if (!this.startDate.value && !this.endDate.value) {
      this.setState({ error: 'Debe especificar al menos una fecha' });
    } else {
      exporterService.makeExport(this.startDate.value, this.endDate.value)
        .then(() => this.setState({ loading: false }));
      this.setState({ error: null, loading: true });
    }
  }

  render() {
    const { error, loading } = this.state;
    return (
      <div className="column">
        <div className="content">
          <div className="section">
            <h1 className="title is-1 has-text-centered">Exportador de Entregas</h1>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Desde</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input
                      name="start"
                      className="input"
                      type="text"
                      placeholder="Desde..."
                      ref={(input) => { this.startDate = input; }}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Hasta</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input
                      name="end"
                      className="input"
                      type="text"
                      placeholder="Hasta..."
                      ref={(input) => { this.endDate = input; }}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="buttons is-centered">
              <button className={`button ${!loading ? '' : 'is-loading'}`} onClick={this.makeExport}>Exportar</button>
            </div>
            <ErrorMessage message={error} show={error} />
          </div>
        </div>
      </div>
    );
  }
}

export default Export;
