import React, {Component} from 'react';
import ListItem from '../ListItem';
import ListForm from '../../containers/listFormContainer';
import ConfirmModal from '../../../../components/ConfirmModal';
import SearchInput from '../../../../components/SearchInput';

class TableLists extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { listToBeDeleted: null };
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.setListToBeDeleted = this.setListToBeDeleted.bind(this);
    this.confirmListDelete = this.confirmListDelete.bind(this);
  }

  componentDidMount() {
    this.props.fetchLists();
  }

  closeDeleteModal() {
    this.setState({ listToBeDeleted: null });
  }

  setListToBeDeleted(listId) {
    this.setState({ listToBeDeleted: listId });
  }

  confirmListDelete(listId) {
    this.props.removeList(listId);
    this.setState({ listToBeDeleted: null });
  }

  render() {
    const { listToBeDeleted } = this.state;
    const { lists, setSearch } = this.props;
    const { setEditableList } = this.props;
    const rows = lists.map(list =>
      <ListItem
        list={list}
        key={list.id}
        onEditClick={setEditableList}
        onDeleteClick={this.setListToBeDeleted}
      />
    );
    const modal = !listToBeDeleted ? null : (
      <ConfirmModal
        title="Confirmaci&oacute;n"
        message="Esta seguro de eliminar esta lista?"
        onCancel={this.closeDeleteModal}
        onConfirm={() => this.confirmListDelete(listToBeDeleted)}
      />
    );
    return (
      <React.Fragment>
        {modal}
        <ListForm />
        <SearchInput onChange={setSearch} />
        <div className="box is-marginless">
          <table className="table is-narrow is-hoverable is-fullwidth">
            <thead>
            <tr>
              <th>C&oacute;digo</th>
              <th>Precio distancia</th>
              <th>Precio volumen</th>
              <th>Precio piso</th>
              <th>Precio peso</th>
            </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default TableLists;
