import React, { Component } from 'react';
import SearchInput from '../../../../../../components/SearchInput';

class FrequentPointsModal extends Component {
  constructor(props) {
    super();
    this.props = props;
  }

  componentDidMount() {
    // via container
    this.props.fetchPoints();
  }

  render() {
    const {
      // via container
      points,
      search,
      setSearch,
      // via props
      closeModal,
      onPointSelect,
    } = this.props;
    const pointsList = points.map(point => (
      <li key={point.id} onClick={() => onPointSelect(point)}>
        <a>
          {point.nombre}: CP {point.codigo_postal}, {point.direccion_origen}
        </a>
      </li>
    ));
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-content">
          <div className="box">
            <h1 className="title">Puntos Frecuentes</h1>
            <SearchInput value={search} onChange={setSearch} size />
            <aside className="menu">
              <ul className="menu-list">
                {pointsList}
              </ul>
            </aside>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeModal} />
      </div>
    );
  }
}

export default FrequentPointsModal;
