import React, { Fragment } from 'react';
import moment from 'moment';

const DeliveryItem = ({ delivery, url, history, rol, onChange }) => {
  const onClick = () => history.push(`${url}/${delivery.id}`);
  const style = { cursor: 'pointer' };
  const { estado_usuario_correo } = delivery;
  if (estado_usuario_correo === 'CORREO ENVIADO') {
    style.backgroundColor = '#f1f97f';
  } else if (estado_usuario_correo === 'ACEPTADO') {
    style.backgroundColor = '#98f998';
  } else if (estado_usuario_correo === 'RECHAZADO') {
    style.backgroundColor = '#f98e87';
  }
  if (delivery.aditionalWeight == 1) {
    style.fontWeight = 'bold'
  }

  const clienteCol = !rol ? null : <td onClick={onClick} style={style}>{delivery.user.usuario}</td>;

  const checkCheckbox = (delivery) => {

    if (rol) {
      return false
    } else {

      if ((delivery.estado_pago === "Pagado" || delivery.estado_pago == "Procesando")) {
        return true
      }
      if (delivery.cargo_transporte) {
        return true
      }
      return false
    }
  }

  return (

    <tr >
      <td><input
        type="checkbox"
        name={delivery.id}
        onChange={onChange}
        disabled={checkCheckbox(delivery)}
      /></td>
      <td onClick={onClick} style={style} >{delivery.id}</td>
      {clienteCol}
      <td onClick={onClick} style={style}>{!delivery.created_at ? '-' : moment(delivery.created_at).format('DD/MM/YY')}</td>
      <td onClick={onClick} style={style}>{!delivery.fecha_entrega ? '-' : moment(delivery.fecha_entrega).format('DD/MM/YY')}</td>
      <td onClick={onClick} style={style}>${delivery.cotizacion}</td>
      <td onClick={onClick} style={style}>{delivery.volumen.toFixed(1)}</td>
      <td onClick={onClick} style={style}>{!delivery.cargo_transporte ? 'No' : 'Si'}</td>
      <td onClick={onClick} style={style}>{delivery.facturacion}</td>
      <td onClick={onClick} style={style}>{delivery.remito}</td>
      <td onClick={onClick} style={style}>{delivery.localidad_origen ? delivery.localidad_origen : delivery.cp_origen} {delivery.nombre_origen}</td>
      <td onClick={onClick} style={style}>{delivery.localidad_destino ? delivery.localidad_destino : delivery.cp_destino} {delivery.nombre_destino}</td>
      <td onClick={onClick} style={style}>{delivery.estado || ''}</td>
      <td onClick={onClick} style={style}>{!delivery.fecha_disponible ? '-' : moment(delivery.fecha_disponible).format('DD/MM/YY')}</td>
      <td onClick={onClick} style={style}>{delivery.peso.toFixed(2)}</td>
      <td onClick={onClick} style={style}>{delivery.observaciones}</td>
      <td onClick={onClick} style={style}>{delivery.aditionalWeight == 1 ? 'Sí' : 'No'}</td>
      <td onClick={onClick} style={style}>{delivery.estado_pago == null ? 'Pendiente' : delivery.estado_pago}</td>
    </tr>
  );
};

export default DeliveryItem;
