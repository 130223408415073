import React from 'react';
import { dispatch } from '@rematch/core';
import TableLists from './containers/tableListsContainer';
import Icon from '../../components/Icon';

const newListTemplate = {
  codigo: '',
  precio_distancia: '',
  precio_volumen: '',
  precio_piso: '',
};

const PriceList = () => {
  return (
    <div className="column route-content">
      <div className="content">
        <section className="section">
          <h1 className="title is-1 has-text-centered">Listas de Precio</h1>
          <button
            className="button is-primary is-large"
            onClick={() => dispatch.priceList.setEditableList({ ...newListTemplate })}
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              width: '55px',
              borderRadius: '100%'
            }}
          >
            <Icon icon="fas fa-plus" />
          </button>
          <TableLists/>
        </section>
      </div>
    </div>
  );
};

  export default PriceList;
