import React from 'react';

const ProductCardView = ({ product }) => (
  <div className="card">
    <header className="card-header">
      <p className="card-header-title">
        {product.producto}
      </p>
    </header>
    <div className="card-content">
      <div className="content">
        <p>Cantidad: {product.cantidad}</p>
        <p>Volumen(cm): {product.ancho}x{product.alto}x{product.profundo}</p>
        <p>Valor declarado: ${product.valor_declarado}</p>
        <p>Observaciones: {product.observaciones}</p>
      </div>
    </div>
  </div>
);

export default ProductCardView;
