import React, { Component } from "react";
import EditableField from "../../../../../../components/EditableField";
import moment from "moment";

class MercadoPagoModal extends Component {
  constructor() {
    super();
    this.state = { disabled: false};

  }

  save = () => {
    this.setState({disabled: true})
    this.props.save();
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title is-marginless">
              Pago de entregas por medio de Mercado Pago
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={this.closeModal}
            />
          </header>
          <section className="modal-card-body">
            <span>
              Lo redigiremos a mercado pago para pagar las entregas
              seleccionadas!{" "}
            </span>
          </section>
          <footer className="modal-card-foot">
            <div style={{ textAlign: "center", width: "100%" }}>
              <button className="button is-success" disabled={this.state.disabled} onClick={this.save}>
                Aceptar
              </button>
              <button className="button" onClick={this.closeModal}>
                Cancelar
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default MercadoPagoModal;
