import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu';
class Header extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { openMenu: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  render() {
    const { logout, setOpenChangePasswordModal, rol } = this.props;
    const { openMenu } = this.state;
    const openMenuClass = !openMenu ? '' : 'is-active';
    return (
      <nav
        className="navbar is-black is-hidden-tablet"
        aria-label="main navigation"
        id="navbar"
        style={{ backgroundColor: 'rbg(10,10,10)' }}
      >
        <div className="navbar-brand">
          <Link
            className="navbar-item"
            to="/"
            style={{ padding: '0', paddingLeft: '10px' }}
          >
            <img src={require('../../image/logo-negativo.png')} alt="logo" />
          </Link>
          <div className={`navbar-burger ${openMenuClass}`} onClick={this.toggleMenu}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={`navbar-menu ${openMenuClass}`}>
          <div className="navbar-end">
            <MobileMenu role={rol} />
            <a className="navbar-item" onClick={() => setOpenChangePasswordModal(true)}>Cambiar Contrase&ntilde;a</a>
            <a className="navbar-item" onClick={logout}>Salir</a>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;