import axios from 'axios';
import { dispatch } from '@rematch/core';
import user from './user';
import frequentPoint from './frequentPoint';
import status from './status';
import priceList from './priceList';
import delivery from './delivery';
import auth from './auth';
import postalCode from './postalCode';
import masterProduct from './masterProduct';
import payment from './payment'

axios.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('access_token');
    dispatch.auth.setLogged(false);
    dispatch.auth.setUser('');
    dispatch.auth.setRol(0);
  }
  return Promise.reject(error);
});

export default {
  user,
  frequentPoint,
  status,
  priceList,
  delivery,
  auth,
  postalCode,
  masterProduct,
  payment
};
