import api from '../../services/api/';

const effects = {
  async fetchUsers() {
    try {
      const data = await api.user.getAll();
      this.updateUsers(data);
    } catch (error) {
      console.log('Error in fetchUsers', error);
      // this.failFetchUsers(error);
    }
  },
  async saveUser(user) {
    try {
      const { id, ...content } = user;
      if (!id) {
        await api.user.post(content)
      } else {
        await api.user.put(id, content);
      }
      this.setEditableUser(null);
      this.fetchUsers();
    } catch (error) {
      console.log('Error in saveUser', error);
      // this.failFetchUsers(error);
    }
  },
  async removeUserEffect(userId) {
    try {
      if (userId) {
        await api.user.delete(userId);
        this.removeUser(userId);
      }
    } catch (error) {
      console.log('Error in removeUserEffect', error);
      // this.failFetchUsers(error);
    }
  }
};

export default effects;
