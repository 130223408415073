import React, { Component } from 'react';
import moment from 'moment';

class DateRangeModal extends Component {
  constructor(props) {
    super();
    this.props = props;
    const { startDate, endDate } = this.props;
    const start = !startDate ? null : startDate.format('YYYY-MM-DD');
    const end = !endDate ? null : endDate.format('YYYY-MM-DD');
    this.state = { start, end };
    this.onOkClick = this.onOkClick.bind(this);
    this.cleanEnd = this.cleanEnd.bind(this);
  }

  componentDidMount() {
    const options = {
      lang: 'es',
      overlay: true,
    };
    new window.bulmaCalendar(this.startInput, {
      ...options,
      onSelect: (date) => this.setState({ start: moment(date).format('YYYY-MM-DD') }),
    });
    new window.bulmaCalendar(this.endInput, {
      ...options,
      onSelect: (date) => this.setState({ end: moment(date).format('YYYY-MM-DD') }),
    });
  }

  cleanEnd() {
    this.setState({ end: null });
  }

  onOkClick() {
    const { start, end } = this.state;
    console.log(start, end);
    const startDate = !start ? null : moment(start);
    const endDate = !end ? null : moment(end);
    this.props.onOk({ startDate, endDate });
  }

  render() {
    const { onCancel } = this.props;
    const { start, end } = this.state;
    console.log(start, end);
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={onCancel} />
        <div className="modal-content">
          <div className="box">
            <h1 className="is-title">Filtrar por Fecha</h1>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Desde</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      name="start"
                      ref={input => { this.startInput = input; }}
                      value={start || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Hasta</label>
              </div>
              <div className="field-body">
                <div className={`field ${end ? 'has-addons' : ''}`}>
                  <div className="control is-expanded">
                    <input
                      className="input"
                      type="text"
                      name="end"
                      ref={input => { this.endInput = input; }}
                      value={end || 'Ahora'}
                    />
                  </div>
                  {!end ? null : (
                    <div className="control">
                      <button className="button" onClick={this.cleanEnd}>Limpiar</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="buttons is-centered">
              <button className={`button is-success`} onClick={this.onOkClick}>Filtrar</button>
              <button className="button" onClick={onCancel}>Cerrar</button>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={onCancel} />
      </div>
    );
  }
}

export default DateRangeModal;
