import { connect } from 'react-redux';
import ChangePasswordModal from '../ChangePasswordModal';

const mapStateToProps = state => ({
  loading: state.auth.loading,
  loginError: state.auth.loginError,
  openChangePasswordModal: state.auth.openChangePasswordModal,
});

const mapDispatchToProps = ({ auth: { changePassword, setOpenChangePasswordModal } }) => ({
  changePassword,
  closeModal: () => setOpenChangePasswordModal(false),
});

const changePasswordModalContainer = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);

export default changePasswordModalContainer;