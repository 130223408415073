import React from 'react';
import "./pointForm.css";

const PointForm = ({point, closeModal, setEditablePoint, updatePoint}) => {
  return !point ? null : (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}/>
      <div className="modal-content">
        <div id="menuPoint">
          <div className="field">
            <label className="label">Nombre</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Nombre..."
                value={point.nombre}
                onChange={({target}) => setEditablePoint({...point, nombre: target.value})}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Direcci&oacute;n</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Direcci&oacute;n..."
                value={point.direccion_origen}
                onChange={({target}) => setEditablePoint({...point, direccion_origen: target.value})}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Codigo Postal</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Codigo postal..."
                value={point.codigo_postal}
                onChange={({target}) => setEditablePoint({...point, codigo_postal: target.value})}
              />
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-link" onClick={() => updatePoint(point)}>
                {!point.id ? 'Crear' : 'Editar'}
              </button>
            </div>
            <div className="control">
              <button className="button is-text" onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={closeModal}/>
    </div>
  );
};

export default PointForm;
