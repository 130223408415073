import React from 'react';
import Icon from '../../../../components/Icon';

const ListItem = ({ list, onEditClick, onDeleteClick }) => {
  return (
    <tr key={list.id}>
      <td>{list.codigo}</td>
      <td>{list.precio_distancia}</td>
      <td>{list.precio_volumen}</td>
      <td>{list.precio_piso}</td>
      <td>{list.precio_peso}</td>
      <td>
        <button
          className="button is-small is-warning"
          onClick={() => onEditClick(list)}
          title="Editar"
        >
          <Icon icon="fas fa-edit" />
        </button>
        <button
          className="button is-small is-danger"
          onClick={() => onDeleteClick(list.id)}
          title="Eliminar"
        >
          <Icon icon="fas fa-trash" />
        </button>
      </td>
    </tr>
  );
};

export default ListItem;
