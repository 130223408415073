import axios from 'axios';
import { apiUrl } from '../../config/path';

const authHeader = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });

class MasterProduct {
  static async getAll() {
    const response = await axios.get(`${apiUrl}/productos`, authHeader());
    return response.data;
  }
  static async put(productId, product) {
    const response = await axios.put(`${apiUrl}/productos/${productId}`, product, authHeader());
    return response.data;
  }
  static async post(product) {
    const response = await axios.post(`${apiUrl}/productos`, product, authHeader());
    return response.data;
  }
  static async delete(productId) {
    const response = await axios.delete(`${apiUrl}/productos/${productId}`, authHeader());
    return response.data;
  }
}

export default MasterProduct;
