import { createSelector } from 'reselect';
import { filter } from '../../utils/helpers';

export const users = state => state.users;
export const search = state => state.search;

export const filteredUsers = createSelector(
  users,
  search,
  (users, search) => Object.values(users).filter(filter(search, ['id'])),
);
