import React, { Component } from 'react';
import { dispatch } from '@rematch/core';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from '../Header';
import SideMenu from '../SideMenu';
import Login from '../Login';
import Home from '../../routes/Home';
import Users from '../../routes/Users';
import FrequentPoints from '../../routes/FrecuentPoints';
import PriceList from '../../routes/PriceList';
import Deliveries from '../../routes/Deliveries';
import Quote from '../../routes/Quote';
import Export from '../../routes/Export';
import MasterProducts from '../../routes/MasterProducts';
import Payment from "../../routes/Payment"
import './App.css';
import ReactGA from 'react-ga';

class App extends Component {
  constructor(props) {
    super();
    this.props = props;
  }
  componentDidMount() {
    dispatch.status.fetchStatus();
    dispatch.delivery.fetchDeliveries();
    dispatch.frequentPoint.fetchPoints();
    dispatch.masterProduct.fetchProducts();
    dispatch.auth.loggedUser();
    ReactGA.initialize('UA-196727422-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

  }

  componentDidUpdate(prevProps) {
    if (!prevProps.logged && this.props.logged) {
      dispatch.status.fetchStatus();
      dispatch.delivery.fetchDeliveries();
      dispatch.frequentPoint.fetchPoints();
      dispatch.masterProduct.fetchProducts();
      dispatch.auth.loggedUser();
    }
  }

  render() {
    const { logged, rol } = this.props;
    const adminRoutes = !rol ? null : (
      <React.Fragment>
        <Route path="/price-lists" component={PriceList} />
        <Route path="/users" component={Users} />
      </React.Fragment>
    );
    const content = !logged ? <Login /> : (
      <div className="app">
        <Header />
        <div className="container-fluid">
          <div className="columns route-container">
            <SideMenu role={rol} />
            <Route exact path="/" component={Home} />
            <Route path="/quote" component={Quote} />
            <Route path="/deliveries" component={Deliveries} />
            <Route path="/points" component={FrequentPoints} />
            <Route path="/products" component={MasterProducts} />
            <Route path="/export" component={Export} />
            <Route path="/payment" component={Payment} />
            {adminRoutes}
          </div>
        </div>
      </div>
    );

    return (
      <Router>
        {content}
      </Router>
    );
  }
}

export default App;
