import React, { Component } from 'react';
import moment from 'moment';
import ProductMiniCard from './components/ProductMiniCard';
import './summary.css';

class Summary extends Component {
  constructor(props) {
    super();
    this.props = props;

  }

  inputHandler = ({ target: { name: key, value: val, type, checked } }) => {
    const value = type === 'checkbox' ? checked : val;
    this.props.onInputChange({ key, value });
  }

  render() {
    const { quote, products, amount } = this.props;
    const aditionalWeight = !quote.aditionalWeight ? null : (<li>Ayudante contratado</li>)
    const remito = !quote.remito ? null : <li>Remito {quote.remito}</li>
    const itemEdificio = !quote.es_edificio ? null : (
      <li>Edificio {quote.pisos} Pisos</li>
    );
    const itemCobroCargoTransporte = !quote.cargo_transporte ? null : (
      <li>Cobro de viaje a cargo de transporte</li>
    );
    const itemInmendiata = quote.mercaderia_inmediata ? (
      <li>Mercader&iacute;a con disponibilidad inmediata</li>
    ) : (
        <li>Mercader&iacute;a disponible el {moment(quote.fecha_disponible).format('DD/MM/YYYY')}</li>
      );
    const productsList = products.map((product, index) => (
      <ProductMiniCard product={product} key={index} />
    ));
    return (
      <div className="content">
        <h1 className="title is-centered">Resumen</h1>
        <div className="box is-marginless">
          <div className="columns">
            <div className="column is-3 has-text-right-tablet has-text-weight-semibold summary-label">Origen</div>
            <div className="column summary-value">
              <ul>
                <li>{quote.nombre_origen}{!quote.guardar_origen ? '' : ' (A guardar como punto frecuente)'}</li>
                <li>{quote.direccion_origen} Codigo Postal {quote.cp_origen}</li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column is-3 has-text-right-tablet has-text-weight-semibold summary-label">Destino</div>
            <div className="column summary-value">
              <ul>
                <li>{quote.nombre_destino}{!quote.guardar_destino ? '' : ' (A guardar como punto frecuente)'}</li>
                <li>{quote.direccion_destino} Codigo Postal {quote.cp_destino}</li>
                {itemEdificio}
                {itemCobroCargoTransporte}
                {itemInmendiata}
                {aditionalWeight}
                {remito}
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column is-3 has-text-right-tablet has-text-weight-semibold summary-label">Contacto principal</div>
            <div className="column summary-value">
              <ul>
                <li>Nombre: {quote.contacto}</li>
                <li>Email: {quote.contacto_mail}</li>
                <li>{!quote.contacto_telefono ? '' : `Telefono: ${quote.contacto_telefono}`}</li>
                <li>{!quote.contacto_whatsapp ? '' : `Whatsapp: ${quote.contacto_whatsapp}`}</li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column is-3 has-text-right-tablet has-text-weight-semibold summary-label">Productos</div>
            <div className="column summary-value">
              <ul>
                {productsList}
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column is-3 has-text-right-tablet has-text-weight-semibold summary-label">Total</div>
            <div className="column summary-value">
              <ul>
                <li>${amount}</li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column is-3 has-text-right-tablet has-text-weight-semibold summary-label"><input type="checkbox"
              name="draft"
              checked={quote.draft}
              onChange={this.inputHandler} /></div>
            <div className="column summary-value">
              <ul>
                <li>Marcar entrega como borrador</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Summary;
