import api from '../../services/api/';

const effects = {
  async fetchProducts() {
    try {
      const data = await api.masterProduct.getAll();
      this.updateProducts(data);
    } catch (error) {
      console.log('Error in fetchProducts', error);
      // this.failFetchpoints(error);
    }
  },
  async saveProduct({ product, history }) {
    try {
      this.setLoading(true);
      const { id, ...content } = product;
      const data = !id
        ? await api.masterProduct.post(content)
        : await api.masterProduct.put(id, content);
      this.updateProducts([data]);
      this.setLoading(false);
      if (!id) {
        history.push(`/products/${data.id}`);
      }
      this.fetchProducts();
    } catch (error) {
      this.setLoading(false);
      console.log('Error in saveProduct', error);
      // this.failFetchpoints(error);
    }
  },
  async removeProductEffect(productId) {
    try {
      if (productId) {
        await api.masterProduct.delete(productId);
        this.removeProduct(productId);
      }
    } catch (error) {
      console.log('Error in removeProductEffect', error);
      // this.failFetchpoints(error);
    }
  },
 };

export default effects;
