import { connect } from 'react-redux';
import ProductView from '../components/ProductView';
import { select } from '@rematch/select';

const mapStateToProps = (state, { match: { params: { id: productId } } }) => {
  return {
    product: select.masterProduct.productById(state, { productId }),
    loading: select.masterProduct.loading(state),
  };
};

const mapDispatchToProps = ({ masterProduct }) => {
  return {
    updateProduct: product => {
      masterProduct.saveProduct(product);
    },
    removeProduct(productId) {
      masterProduct.removeProductEffect(productId);
    },
  };
};

const productViewContainer = connect(mapStateToProps, mapDispatchToProps)(ProductView);

export default productViewContainer;