import { connect } from 'react-redux';
import Login from '../Login';

const mapStateToProps = state => ({
  hasError: state.auth.loginError,
});

const mapDispatchToProps = ({ auth: { login, setLoginError } }) => ({
  login,
  setLoginError,
});

const loginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default loginContainer;
