import React, { Component } from 'react';
import HorizontalField from '../HorizontalField';

class ChangePasswordModal extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { current: '', change: '', repeat: '', error: null };
    this.onChange = this.onChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  onChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  changePassword() {
    const { current, change, repeat } = this.state;
    if (!current || !change) {
      this.setState({ error: 'Debe llenar todos los campos' });
    } else if (change !== repeat) {
      this.setState({ error: 'Los campos de Nueva y Repetir Nueva deben coincidir' });
    } else {
      this.props.changePassword({ current, change });
      this.setState({ error: null });
    }
  }

  render() {
    const { closeModal, loginError, loading, openChangePasswordModal } = this.props;
    const { current, change, repeat, error } = this.state;
    const errorMessage = !error ? null : (
      <article className="message is-danger">
        <div className="message-body">
          {error}
        </div>
      </article>
    );
    const errorLoginMessage = !loginError ? null : (
      <article className="message is-danger">
        <div className="message-body">
          Contrase&ntilde;a incorrecta
        </div>
      </article>
    );
    return !openChangePasswordModal ? null : (
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-content">
          <div className="box">
            <HorizontalField
              value={current}
              name="current"
              label="Actual"
              onChange={this.onChange}
              type="password"
              disabled={loading}
            />
            <HorizontalField
              value={change}
              name="change"
              label="Nueva"
              onChange={this.onChange}
              type="password"
              disabled={loading}
            />
            <HorizontalField
              value={repeat}
              name="repeat"
              label="Repetir Nueva"
              onChange={this.onChange}
              type="password"
              disabled={loading}
            />
            {errorMessage}
            {errorLoginMessage}
            <div className="buttons is-centered">
              <button className={`button is-success ${!loading ? '' : 'is-loading'}`} onClick={this.changePassword}>Cambiar</button>
              <button className="button" onClick={closeModal}>Cerrar</button>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeModal} />
      </div>
    );
  }
}

export default ChangePasswordModal;
