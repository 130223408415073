import axios from 'axios/index';
import * as PATH from '../../config/path';
import apiService from '../../services/api';

const effects = {
  async login({ email, password }) {
    try {
      const response = await axios.post(`${PATH.apiUrl}/auth/login`, { email, password });
      if (response.headers.authorization) {
        localStorage.setItem('access_token', response.headers.authorization);
        this.setLogged(true);
      } else {
        this.setLoginError(false);
      }
    } catch (error) {
      this.setLoginError(true)
    }
  },
  logout() {
    return new Promise((resolve) => {
      localStorage.removeItem('access_token');
      window.location = PATH.informativeUrl;
      resolve();
    });
  },
  async loggedUser() {
    try {
      const response = await apiService.user.loggedUser();
      const { usuario, rol } = response.data;
      this.setUser(usuario);
      this.setRol(parseInt(rol, 10));
    } catch (error) {
      console.log('Error in fetchDeliveries', error);
    }
  },
  async changePassword({ current, change }) {
    try {
      this.setLoading(true);
      this.setLoginError(false);
      await apiService.auth.resetPassword({ current, change });
      this.setLoading(false);
      this.setOpenChangePasswordModal(false);
    } catch (error) {
      console.log(error);
      this.setLoginError(true);
      this.setLoading(false);
    }
  }
};

export default effects;