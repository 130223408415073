import React from 'react';
import Autosuggest from 'react-autosuggest';
import './autosuggestPostalCode.css';
import apiService from '../../services/api';

/*
const languages = [
  {
    name: 'C',
    year: 1972,
  },
  {
    name: 'C#',
    year: 2000
  }
];*/

/*function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}


function getSuggestions(value) {

  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp('^' + escapedValue, 'i');
  return languages.filter(language => regex.test(language.name));

}*/

function getSuggestionValue(suggestion) {
  return suggestion.codigo_postal;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.barrio  + " - " + suggestion.localidad
    + " - " + suggestion.codigo_postal
    + " - " + suggestion.provincia}</span>
  );
}



class AutosuggestPostalCode extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: []
    };

  }


  onChange = (event, { newValue, method }) => {
    event.target.name = this.props.name;
    event.target.value = newValue;
    this.setState({
    //  value: newValue
      value: typeof newValue !== 'undefined' ? newValue : '',
    });
       this.props.onChange(event);

  };


  async  getPostalCodes(key,code) {
   const data = await apiService.postalCode.validates(key,code);
   this.setState({ suggestions: data });
 };


  onSuggestionsFetchRequested = ({ value }) => {
 if (value.length > 2 ) {
  this.getPostalCodes(this.props.name,value);
}
  /*  this.setState({
    suggestions: getSuggestions(value)

  })*/

  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

   onSuggestionSelected = (event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
};


componentDidMount = () => {
  //vuelvo del step actualizo value
  this.setState({
    value: this.props.inputval
  })
};


componentWillReceiveProps = (next_props) => {

  if (next_props.disabled > 0) {
  // agrego punto frecuente, seteo value con CP
    this.setState({
      value: next_props.value
    })
  }

  if ((next_props.disabled === null) && (next_props.value === '')) {
  // quito punto frecuente, seteo value vacio
    this.setState({
      value: ''
    })
  }
}



  render() {
    const { className, disabled, name,id, placeholder,inputval } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      value: value ? value : '',
      onChange:this.onChange,
      placeholder,
      id,
      name,
      disabled,
      className,
      inputval

    };

    return (
      <Autosuggest
        id={id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected ={this.onSuggestionSelected}
        focusInputOnSuggestionClick={true}
        alwaysRenderSuggestions={false}
        highlightFirstSuggestion={false}
        />
    );
  }
}


export default AutosuggestPostalCode;
