import React from 'react';
import DeliverySummary from './components/DeliverySummary';

const DeliverySummaries = ({ summaries, history, qtyPaymentPending, rol, deliveries }) => {
  const qty = rol ? Object.values(deliveries).filter((delivery) => delivery.estado_pago === "Pendiente").length :
    Object.values(deliveries).filter((delivery) => delivery.estado_pago === "Pendiente" && delivery.cargo_transporte === 0).length
  const summaryItems = summaries.map(summary => (
    <DeliverySummary
      key={summary.id}
      summary={summary}
      history={history}
      rol={rol}
    />
  ));
  return (
    <section className="info-tiles">
      <div className="tile is-ancestor has-text-centered">
        {summaryItems}
        <DeliverySummary
          key={7}
          summary={{ number: qty, estado: "PENDIENTE DE PAGO" }}
          history={history}
          rol={rol}
        />
      </div>
    </section>
  )
};

export default DeliverySummaries;
