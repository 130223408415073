import React, { Component } from 'react';
import Icon from '../../../../components/Icon';
import HorizontalField from '../../../../components/HorizontalField';
import ConfirmModal from '../../../../components/ConfirmModal';

const newProductTemplate = {
  producto: '',
  alto: '',
  ancho: '',
  profundo: '',
  peso: '',
  valor_declarado:''
};

class ProductView extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = { editable: null, productToBeDeleted: null, hasError: false };
    this.setEditable = this.setEditable.bind(this);
    this.cancelEditable = this.cancelEditable.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setProductToBeDeleted = this.setProductToBeDeleted.bind(this);
    this.confirmProductDelete = this.confirmProductDelete.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.field = this.field.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id === 'new') {
      this.setEditable({ editable: { ...newProductTemplate } });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.setState({ editable: null });
    }
  }

  setEditable() {
    this.setState({ editable: { ...this.props.product }});
  }

  cancelEditable() {
    this.setState({ editable: null });
    if (this.props.match.params.id === 'new') {
      this.props.history.push('/products');
    }
  }

  onChange({ target: { name, value } }) {

    if (name !== 'producto'  && value <= 0 && value !== '' ) {
      value = 1;
    }

    const { editable } = this.state;
    this.setState({ editable: { ...editable, [name]: value } });
  }

  onSave() {
    const { editable } = this.state;
    const update =  editable.hasOwnProperty('id');
    var succes = true;

    if (!update && Object.keys(editable).length !== 6 )
       {
        this.setState({ hasError: true });
        succes = false;
       }
      else {
        if (!(Object.values(editable).findIndex(field => !field || parseInt(field, 10) === 0) === -1))
        {
          this.setState({ hasError: true });
          succes = false;
        }
      }

      if (succes) {
        this.setState({ hasError: false });
        this.props.updateProduct({ product: { ...editable }, history: this.props.history });
      }
  }

  setProductToBeDeleted() {
    this.setState({ productToBeDeleted: this.props.product.id });
  }

  confirmProductDelete(productId) {
    this.props.removeProduct(productId);
    this.setState({ producToBeDeleted: null });
    this.props.history.push('/products');
  }

  closeDeleteModal() {
    this.setState({ productToBeDeleted: null });
  }

  field({ label, name, value, error, help, type }) {
    return (
      <HorizontalField
        label={label}
        name={name}
        value={value}
        error={error}
        help={help}
        type={type}
        onChange={this.onChange}
        editable={!!this.state.editable}
        disabled={this.props.loading}
      />
    );
  }

  render() {
    const Field = this.field;
    const { loading } = this.props;
    const { editable, productToBeDeleted, hasError } = this.state;
    const product = editable || this.props.product;
    const errorMessage = !hasError ? null : (
      <article className="message is-danger">
        <div className="message-body">
          Todos los campos son requeridos.
        </div>
      </article>
    );
    const buttons = !editable ? (
      <div className="field is-grouped view-buttons is-pulled-right">
        <p className="control">
          <button className="button is-small" onClick={this.setEditable} title="Editar">
            <Icon key="edit" icon="fas fa-edit" />
          </button>
        </p>
        <p className="control">
          <button className="button is-small is-danger" onClick={this.setProductToBeDeleted} title="Eliminar">
            <Icon key="trash" icon="fas fa-trash" />
          </button>
        </p>
      </div>
    ) : (
      <div className="field is-grouped view-buttons is-pulled-right">
        <p className="control">
          <button
            className={`button is-small is-success ${!loading ? '' : 'is-loading'}`}
            onClick={this.onSave}
            title="Guardar"
          >
            <Icon key="save" icon="fas fa-check" />
          </button>
        </p>
        <p className="control">
          <button
            className={`button is-small ${!loading ? '' : 'is-loading'}`}
            onClick={this.cancelEditable}
            title="Cancelar"
          >
            <Icon key="cancel" icon="fas fa-times" />
          </button>
        </p>
      </div>
    );
    const backButton = (
      <span className="view-buttons is-pulled-left">
        <button
          className="button is-small"
          onClick={() => this.props.history.push('/products')}
        >
          <Icon icon="fas fa-arrow-left" />
        </button>
      </span>
    );
    const modal = !productToBeDeleted ? null : (
      <ConfirmModal
        title="Confirmaci&oacute;n"
        message="¿Esta seguro de eliminar este producto?"
        onCancel={this.closeDeleteModal}
        onConfirm={() => this.confirmProductDelete(productToBeDeleted)}
      />
    );
    return !product ? null : (
      <React.Fragment>
        {modal}
        {backButton}
        {buttons}
        <section className="section" style={{ paddingBottom: '5px' }}>
          <h1 className="title is-1 has-text-centered">Producto</h1>
          <div className="box">
            <div className="columns">
              <div className="column">
                <Field
                  label="Producto"
                  name="producto"
                  value={product.producto}
                  type="text"
                />
                <Field
                  label="Alto (cm)"
                  name="alto"
                  value={product.alto}
                  type="number"
                />
                <Field
                  label="Ancho (cm)"
                  name="ancho"
                  value={product.ancho}
                  type="number"
                />
                <Field
                  label="Profundo (cm)"
                  name="profundo"
                  value={product.profundo}
                  type="number"
                />
                <Field
                  label="Peso (kg)"
                  name="peso"
                  value={product.peso}
                  type="number"
                />
                <Field
                  label="Valor Declarado"
                  name="valor_declarado"
                  value={product.valor_declarado}
                  type="number"
                />
              {errorMessage}
              </div>
            </div>
          </div>
        </section>
        {backButton}
        {buttons}
      </React.Fragment>
    );
  }
}

export default ProductView;
